import React from "react";
import { Button, Col, Modal, Row } from "antd";

import './LogDetails.scss'

type PropsType = {
  record: any,
  close: ()=>void,
}

type ActionPropsType = {
  action: any
}

const Action_USER_PHONE_NUMBER_VERIFIED: React.FC<ActionPropsType> = ({action}) => {
  return (<>
  <Row>
    <Col span={12}>Номер телефона</Col><Col span={12}>{action.phone_number}</Col>
    { action.first_name && <><Col span={12}>Имя</Col><Col span={12}>{action.first_name}</Col></> }
    { action.last_name && <><Col span={12}>Имя</Col><Col span={12}>{action.last_name}</Col></> }
    { action.role && <><Col span={12}>Роль</Col><Col span={12}>{action.role}</Col></>}
    { action.staus && <><Col span={12}>Статус</Col><Col span={12}>{action.staus}</Col></> }
  </Row>
  </>)
}
const Action_SEND_MESSAGE: React.FC<ActionPropsType> = ({action}) => {
  return (<>
  <Row>
    { action.text && <><Col span={12}>Text</Col><Col span={12}>{action.text}</Col></> }
    { action.image_url && <><Col span={12}>ImageUrl</Col><Col span={12}>{action.image_url}</Col></> }
    { action.image_id && <><Col span={12}>ImageID</Col><Col span={12}>{action.image_id}</Col></> }
  </Row>
  </>)
}


const LogsDetails: React.FC<PropsType> = ({record, close})=>{

  const ok = ()=>{
    close()
  }
  const cancel = ()=>{
    close()
  }

  return (
      <>
      <Modal 
        getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
        title="Детали"
        wrapClassName="vertical-center-modal"
        open={true}
        onOk={() => ok()}
        onCancel={() => cancel()}
        footer={null}>
        { record && 
        <Row className="logDetailsContainer">
          <Col span={24}>
          <Row>
            <Col className='label' span={12}>ID</Col>
            <Col className='value' span={12}>{record.id}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Дата</Col>
            <Col className='value' span={12}>{record.created_at}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Действие</Col>
            <Col className='value' span={12}>{record.action}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Level</Col>
            <Col className='value' span={12}>{record.level}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>row_id</Col>
            <Col className='value' span={12}>{record.row_id}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Description</Col>
            <Col className='value' span={12}>{record.description}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col span={24}>Данные</Col>
          </Row>
          <Row>
            <Col span={24}>
            {
              record.action==='USER_PHONE_NUMBER_VERIFIED' && <Action_USER_PHONE_NUMBER_VERIFIED action={record.data} />
            }
            {
              record.action==='SEND_MESSAGE' && <Action_SEND_MESSAGE action={record.data} />
            }
            </Col>
          </Row>
          </Col>
        </Row>  
        }
      </Modal>
    </>
  )

}

export default LogsDetails