import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string;
};

export const IconSend: React.FC<PropsType> = ({ className }) => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

let classNames: Array<string> = ["sendIcon", currentAlgorithm];
if (className) classNames.push(className);


return <svg className={classNames.join(' ')} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.834254 1.59839C0.834254 1.34318 0.834254 0.832775 1.7395 0.832764C2.90338 0.832749 15.8354 7.46818 16.7406 8.23381C17.4648 8.84631 17.1274 9.50985 16.7394 9.76505C15.8344 10.5304 2.7797 17.1621 1.73825 17.1661C0.833008 17.1661 0.833008 16.6557 0.833008 16.4005L1.73916 10.7859C1.84234 10.1734 2.12724 10.0203 2.25679 10.0203L10.016 9.25464C10.1022 9.25464 10.2746 9.2036 10.2746 8.99943C10.2746 8.79526 10.1022 8.74422 10.016 8.74422L2.25679 7.9786C2.12724 7.9786 1.84234 7.82547 1.73916 7.21297C1.43626 5.36227 0.834254 1.8536 0.834254 1.59839Z" fill="#FEFEFE"/>
</svg>
}