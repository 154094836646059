import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppLayout from './Components/admin/Layout/Layout';
import { lkMenuItems } from './Components/lk/Menu/MenuItems';
import { Loader } from './Components/common/Loader';
import { UserRoleEnum } from './types/UserRole.enum';

type PropsType = {
  user: any|null,
  isLoading: boolean
}

export const LkProtectedRoute: React.FC<PropsType> = ({
    user,
    isLoading,
    redirectPath = '/login',
  }: any) => {
    let location = useLocation();
    
    if(isLoading){
      return <Loader />
    }

    if (!user && !isLoading) {
      return <Navigate to={redirectPath} replace state={{from: location}} />;
    }
  
    if(user && [UserRoleEnum.Admin, UserRoleEnum.SuperAdmin].includes(user.role)) {
      return <div>Forbidden</div>
    }

    return <AppLayout menuItems={lkMenuItems}>
    <Outlet />
    </AppLayout>
    
  };