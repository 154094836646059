import React from "react";
import { useParams } from "react-router-dom";
import {
  getChatByIdAndOptionId,
  useGetCreateChatsOptionsQuery,
} from "../../../store/webchatApiSlice";

import "./LkChatText.scss";
import { GenerationHistory } from "../../../Components/GenerationHistory/GenerationHistory";
import { Chat } from "../../../Components/Chat/Chat";

export const LkChatText: React.FC = () => {
  const params = useParams();
  const { neuronet_id, option_id, chat_id } = params;
  const { data: createChatData } = useGetCreateChatsOptionsQuery({});

  const chatData = getChatByIdAndOptionId(
    createChatData,
    neuronet_id,
    option_id
  );

  return (
    <>
      <div className="lkChatText">
        <div className="lkChatTextHeader">
          {chatData?.image &&
            <span className="lkChatTextHeaderImage">
              <img src={chatData?.image?.url} width="32px" height="32px" />
            </span>
          }
          <span className="lkChatTextHeaderText">{chatData?.name}</span>
        </div>
        <div className="lkChatTextContent">
          <span className="lkChatTextGenerationHistory">
            <GenerationHistory neuronet_id={neuronet_id}
              option_id={option_id}
              chat_id={chat_id} />
          </span>
          <span className="lkChatTextChat">
            <Chat
            chatData={chatData}
              neuronet_id={neuronet_id}
              option_id={option_id}
              chat_id={chat_id}
            />
          </span>
        </div>
      </div>
    </>
  );
};
