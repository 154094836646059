import React, { useEffect, useState } from "react";
import {
  useGetChatsQuery,
  useGetTextPreviewQuery,
} from "../../store/webchatApiSlice";
import { IconPlus } from "../Icons/IconPlus";

import "./GenerationHistory.scss";
import { IconEdit } from "../Icons/IconEdit";
import { useNavigate } from "react-router-dom";


type PropsType = {
  neuronet_id: string|undefined
  option_id: string|undefined
  chat_id: string|undefined
}

export const GenerationHistory: React.FC<PropsType> = ({neuronet_id, option_id, chat_id}) => {
const navigate = useNavigate()

  const { data, isLoading } = useGetTextPreviewQuery({
    page: 1,
    limit: 10,
    output_type: "text",
    neuronet_id, option_id
  });

  const onclick = (chat: any)=>()=>{
    navigate(`/lk/text/${chat.neuronet_id}/${chat.option_id||0}/${chat.id}`)
  }
  
  const newChatClick = ()=>{
    navigate(`/lk/text/${neuronet_id}/${option_id||0}`)
  }

  return (
    <>
      <div className="generationHistory">
        <div className="generationHistoryHeader">
          <span className="generationHistoryHeaderTitle">
            История генераций
          </span>
          <span className="generationHistoryHeaderIcon" onClick={newChatClick}>
            <IconPlus />
          </span>
        </div>
        <div className="generationHistoryMessages">
          {data &&
            data.map((chat: any) => {
              let date = new Date(chat.created_at);
              return (
                <div key={chat.id} className="generationHistoryMessage" onClick={onclick(chat)}>                  
                  <div className="generationHistoryMessageIcon">
                    <IconEdit />
                  </div>
                    <div className="generationHistoryMessageContainer">
                      <div className="generationHistoryMessageTitle">{chat.name}</div>
                      <div className="generationHistoryMessageDate">{date.toLocaleDateString()}</div>
                    </div>
                  </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
