import React, { useEffect } from 'react';
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../store/store';
import { delMessage, setStatus } from '../../store/notificationSlice';
import { NotificationMeaasge } from '../../types/NotificationMessage';
//   const Context = React.createContext({ name: 'Default' });
  
  const App: React.FC = () => {
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()

    useEffect(()=>{
        const unsubscribe = store.subscribe(storeChangeListener)
        return ()=>{
            unsubscribe();
        }
    })
  
    const storeChangeListener = ()=>{
        const current_notifications = store.getState().notifications.messages
        if(current_notifications.length>0){
            current_notifications.forEach((message: NotificationMeaasge|undefined, idx: number) => {
                if(message && message.status==='new'){
                    const notifycationFunc = api[message.type]
                    notifycationFunc(Object.assign({},{
                        message: message.message,
                        description: message.description,
                        placement: message.placement,
                        key: message.id,
                        onClose:()=>{
                            dispatch(delMessage({message}))
                        }
                    }));
                    dispatch(setStatus({id: message.id, status: 'showed'}))
                }

            })
        }
    }
    

    return (<>
        {contextHolder}
    </>);
  };
  
  export default App;