import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string;
};

export const IconUsers: React.FC<PropsType> = ({ className }) => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

let classNames: Array<string> = ["customIcon", currentAlgorithm];
if (className) classNames.push(className);

return <svg className={classNames.join(' ')} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.26503 0.760742C2.73982 0.760742 1.49985 2.00324 1.49985 3.53158C1.49985 5.03074 2.66996 6.24407 4.19518 6.29658C4.24175 6.29074 4.28832 6.29074 4.32325 6.29658C4.33489 6.29658 4.34071 6.29658 4.35235 6.29658C4.35817 6.29658 4.35817 6.29658 4.364 6.29658C5.85428 6.24407 7.02439 5.03074 7.03021 3.53158C7.03021 2.00324 5.79025 0.760742 4.26503 0.760742Z" fill="#7479F6"/>
<path d="M7.22232 7.84824C5.59814 6.76324 2.94939 6.76324 1.31357 7.84824C0.574248 8.34407 0.166748 9.01491 0.166748 9.73241C0.166748 10.4499 0.574248 11.1149 1.30775 11.6049C2.12275 12.1532 3.19389 12.4274 4.26503 12.4274C5.33618 12.4274 6.40732 12.1532 7.22232 11.6049C7.95582 11.1091 8.36332 10.4441 8.36332 9.72074C8.3575 9.00324 7.95582 8.33824 7.22232 7.84824Z" fill="#7479F6"/>
<path d="M10.6628 3.87574C10.7559 5.00741 9.95257 5.99908 8.84067 6.13324C8.83485 6.13324 8.83485 6.13324 8.82903 6.13324H8.81157C8.77664 6.13324 8.74171 6.13324 8.7126 6.14491C8.14792 6.17408 7.62982 5.99324 7.23978 5.66074C7.83939 5.12407 8.18285 4.31908 8.113 3.44408C8.07225 2.97158 7.90925 2.53991 7.66475 2.17241C7.88596 2.06158 8.1421 1.99158 8.40407 1.96824C9.54507 1.86908 10.5638 2.72074 10.6628 3.87574Z" fill="#7479F6"/>
<path d="M11.8271 9.27158C11.7805 9.83741 11.4196 10.3274 10.8141 10.6599C10.232 10.9807 9.4985 11.1324 8.77082 11.1149C9.18996 10.7357 9.43446 10.2632 9.48103 9.76157C9.53924 9.03824 9.19578 8.34408 8.50885 7.78991C8.11882 7.48074 7.66475 7.23574 7.16992 7.05491C8.45646 6.68158 10.0748 6.93241 11.0703 7.73741C11.6059 8.16908 11.8795 8.71158 11.8271 9.27158Z" fill="#7479F6"/>
</svg>
}