import React from "react";

type PropsType = {
  image: any
}

function getReadableFileSizeString(fileSizeInBytes: number) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}


export const ImageInfo: React.FC<PropsType> = ({image}) => {
  return <>
    <div className="selectedImages">
      <div className="selectedImage"
        style={{ backgroundImage: `url(${image.url})` }}></div>
      <div>
        <div>
          Size: {image.width} x {image.height}
        </div>
        <div>
          File size: {getReadableFileSizeString(image.size)}
        </div>
      </div>
    </div>
  </>
}