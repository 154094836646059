import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const IconLinearEye: React.FC = ()=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

return <svg className={"customIcon "+currentAlgorithm} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.9999 5.11832C6.95604 5.11832 6.11323 5.96113 6.11323 7.00498C6.11323 8.04884 6.95604 8.89165 7.9999 8.89165C9.04375 8.89165 9.88656 8.04884 9.88656 7.00498C9.88656 5.96113 9.04375 5.11832 7.9999 5.11832ZM5.11323 7.00498C5.11323 5.40884 6.40375 4.11832 7.9999 4.11832C9.59604 4.11832 10.8866 5.40884 10.8866 7.00498C10.8866 8.60113 9.59604 9.89165 7.9999 9.89165C6.40375 9.89165 5.11323 8.60113 5.11323 7.00498Z" fill="#2B3858"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.50489 5.00296C3.09884 2.49739 5.43458 0.984985 7.9999 0.984985C10.5653 0.984985 12.9012 2.49752 14.4951 5.00329C14.8584 5.57285 15.0232 6.30242 15.0232 7.00165C15.0232 7.70088 14.8584 8.43045 14.4951 9.00001C12.9012 11.5058 10.5653 13.0183 7.9999 13.0183C5.43446 13.0183 3.09863 11.5058 1.50468 9.00001C1.14137 8.43045 0.976562 7.70088 0.976562 7.00165C0.976562 6.30229 1.14143 5.57257 1.50489 5.00296ZM7.9999 1.98499C5.85866 1.98499 3.80783 3.24579 2.34844 5.54001L2.34802 5.54067C2.11163 5.91101 1.97656 6.44115 1.97656 7.00165C1.97656 7.56215 2.11163 8.09229 2.34802 8.46263L2.34844 8.46329C3.80783 10.7575 5.85866 12.0183 7.9999 12.0183C10.1411 12.0183 12.192 10.7575 13.6514 8.46329L13.6518 8.46263C13.8882 8.09229 14.0232 7.56215 14.0232 7.00165C14.0232 6.44115 13.8882 5.91101 13.6518 5.54067L13.6514 5.54001C12.192 3.24579 10.1411 1.98499 7.9999 1.98499Z" fill="#2B3858"/>
</svg>
}