import { Avatar, Flex } from "antd";
import React from "react";
import { User } from "../../types/User.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Link } from "react-router-dom";

type PropsType = {
  user?: User
}

export const UserAvatar: React.FC<PropsType> = ({user}) => {

  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const getAvatarUrl = (user?: User) => {
    if(user?.profile?.avatar) return user.profile?.avatar
    if(currentAlgorithm==='dark') return '/images/avatar-dark.png'
    return '/images/avatar-light.png'
  }

  const avatar_url = getAvatarUrl(user)

  return <Flex align="center" gap={'12px'}>
    <Avatar src={avatar_url}>
    </Avatar>
    <Link to={`/admin/user/${user?.id}`}>
      <Flex gap={'8px'}>
        <span>
          {user?.profile?.first_name?user?.profile?.first_name:''}
        </span>
        <span>
          {user?.profile?.last_name?user?.profile?.last_name:''}
        </span>
      </Flex>
    </Link>
  </Flex>

}
