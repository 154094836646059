import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Icon from "@ant-design/icons/lib/components/Icon";


export const IconStar: React.FC = ()=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

const svgIcon = ()=> <svg className={"customIcon "+currentAlgorithm} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.00029 0.93381C6.44656 0.135304 7.55346 0.135304 7.99974 0.93381L9.61568 3.82519L12.7537 4.53335C13.6203 4.72893 13.9623 5.82604 13.3715 6.51512L11.2323 9.01025L11.5557 12.3393C11.645 13.2587 10.7495 13.9367 9.93809 13.5641L7.00001 12.2148L4.06193 13.5641C3.25052 13.9367 2.35502 13.2587 2.44434 12.3393L2.76777 9.01025L0.628504 6.51512C0.0377077 5.82604 0.37976 4.72893 1.24637 4.53335L4.38434 3.82519L6.00029 0.93381ZM7.00001 1.54065L5.38407 4.43202C5.22059 4.72453 4.94665 4.93195 4.6292 5.00359L1.49122 5.71176L3.63049 8.20689C3.84691 8.45931 3.95154 8.79492 3.91882 9.13171L3.59539 12.4608L6.53348 11.1115C6.83071 10.975 7.16931 10.975 7.46655 11.1115L10.4046 12.4608L10.0812 9.13171C10.0485 8.79492 10.1531 8.45931 10.3695 8.20689L12.5088 5.71176L9.37083 5.00359C9.05337 4.93195 8.77944 4.72453 8.61596 4.43202L7.00001 1.54065Z" fill="#2B3858"/>
</svg>
  return <Icon component={svgIcon} />
}