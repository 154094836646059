import { Button, Flex, Pagination} from "antd";
import React, { useState } from "react";
import { useGetAllImagesPreviewQuery, useGetImagesPreviewQuery } from "../../../store/webchatApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./AdminHistoryImageGenerations.scss"
import { Loader } from "../../../Components/common/Loader";
import { ChatImageCard } from "../../../Components/ChatImageCard/ChatImageCard";
import { useNavigate } from "react-router-dom";


type PropsType = {
  showFull: boolean
}

export const AdminHistoryImageGenerations: React.FC<PropsType> = ({showFull}) => {
  const navigate = useNavigate();

  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [ pagination, setPagination] = useState({page: 1, limit: showFull?24:12})
  const { data, isLoading, isError  } = useGetAllImagesPreviewQuery({
    page: pagination.page,
    limit: pagination.limit,
  });

  if (isLoading) return <Loader />
  if(isError) return <>error</>
  const [messages, count] = data?data:[[],0]
  
  return (
    <div className={'adminHistoryImageGenerations '+currentAlgorithm} style={{ }}>
      <div className="historyImageGenerationsHeader">
          <span>Генерация изображений</span>
          <span>
            {!showFull &&   <Button type="primary" onClick={()=>navigate('images')}>Смотреть все генерации</Button>}
          </span>
      </div>
      <Flex className='chatMessageImageContainer'>
          {messages &&
            messages.map((item: any) => {
              return <ChatImageCard mode="admin" key={item.id} item={item} />
          })}
      </Flex>
      <Pagination onChange={(page)=>{
        setPagination({...pagination,page})
      }} align="end" defaultCurrent={pagination.page} pageSize={pagination.limit} total={count} />
  </div>
  );
};
