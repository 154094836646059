import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        users: null,
        editedUser: null
    },
    reducers:{
        setCurrentUser: (state, action)=>{
            const { user } = action.payload
            state.currentUser = user 
        },
        setUsers: (state, action)=>{
            const {users} = action.payload
            state.users = users
        },
        setEditedUser: (state, action)=>{
            const user = action.payload
            state.editedUser = user
        }
    }
})

export const { setCurrentUser, setUsers, setEditedUser} = userSlice.actions

export default userSlice.reducer

export const getCurrentUser = (state: RootState) => state.user.currentUser
export const getEditedUser = (state: RootState) => state.user.editedUser