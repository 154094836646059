// import { Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import {
  useGetCreateChatsOptionsQuery,
} from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { Badge, Button, Flex, Pagination, Table, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { IconPlus } from "../../../Components/Icons/IconPlus";
import { SupoortCreateRequest } from "./SupportCreateRequest";
import { useGetSupportRequestsCountsQuery, useGetSupportRequestsQuery } from "../../../store/supportApiSlice";
import { ImageNoMail } from "../../../Components/Icons/ImageNoMail";
import { SupportRequestCard } from "./SupportRequestCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { PlusOutlined } from "@ant-design/icons";


export const Support: React.FC = () => {

  const calcCount = (activeTab: string, suportRequesrtsCount: any) => {
    if(!suportRequesrtsCount) return 0
    switch(activeTab){
      case 'all':
        return suportRequesrtsCount.reduce((a: number, item: any)=>{
          a=a + Number(item.count)
          return a
        },0)
      case 'open':
        return suportRequesrtsCount.filter((item: any)=>item.status==='OPEN').reduce((a: number, item: any)=>{
          a=+item.count
          return a
        },0)
      case 'closed':
        return suportRequesrtsCount.filter((item: any)=>item.status==='CLOSED').reduce((a: number, item: any)=>{
          a=+item.count
          return a
        },0)
    }
  }

  const getBadgeColor = (tab: string, activeTab: string, currentAlgorithm: string) => {

    let color = 'var(--Text-text-placeholder, #979AA4)'
    let background = 'var(--background-bg-active, #2B3858)'
    if(tab===activeTab){
      color = 'var(--Text-text-special, #7479F6)'
    }
    return {
      color,
      background,
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '500',
    }
  }


  const params = useParams()
  const [ activeTab, setActiveTab ] = useState<string>(params.page||'all')
  const [ showForm, setShowForm ] = useState<boolean>(false)
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 20,
    current: 1,
  });
  const {data} = useGetSupportRequestsQuery({status: activeTab, page: pagination.current, limit: pagination.pageSize})
  const {data: suportRequesrtsCount} = useGetSupportRequestsCountsQuery({})
  
  const items: TabsProps['items'] = [
    { key: 'all', label: <div style={{width: '64px'}}>Все
      {suportRequesrtsCount?<Badge style={{...getBadgeColor('all', activeTab, currentAlgorithm)}} offset={[12,-5]} count={calcCount('all', suportRequesrtsCount)}></Badge>:''}
      </div> },
    { key: 'open', label:  <div style={{width: '100px'}}>Открытые
      {suportRequesrtsCount?<Badge style={{...getBadgeColor('open', activeTab, currentAlgorithm)}} offset={[12,-5]} count={calcCount('open', suportRequesrtsCount)}/>:''}
    </div>},
    { key: 'closed', label: <div style={{width: '100px'}}>Закрытые{suportRequesrtsCount?<Badge style={{...getBadgeColor('closed', activeTab, currentAlgorithm)}} offset={[12,-5]} count={
      calcCount('closed', suportRequesrtsCount)} />:''}
      </div>},
  ];

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }
  const close = ()=>{
    setShowForm(false)
  }

  let activeCount = calcCount(activeTab, suportRequesrtsCount)

  return (
    <div className={"supportPage "+currentAlgorithm}>
      <Flex style={{width: '100%', alignItems: 'center'}}>
        <Flex style={{width: '100%'}}>
          <Tabs defaultActiveKey={activeTab} onChange={onTabChange} items={items} style={{width: '330px'}}/>
        </Flex>
        <Flex style={{width: '20%'}}>
          <Button type="primary" onClick={()=>{setShowForm(true)}} icon={<PlusOutlined />} >
            Написать заявку
          </Button>
        </Flex>

      </Flex>
      {/* { data && <Flex align="flex-start" justify="flex-start" vertical={true} style={{width:'100%', height: '100%', gap: '24px'}}>
       {data[0].map((item: any) => {
        return <SupportRequestCard item={item} />
       })}
      <Pagination onChange={(page)=>{
        setPagination({...pagination,current: page})
      }} align="end" defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={activeCount} />
      </Flex>
      }
      {!data || (data && data[1]===0) &&
      <Flex align="center" justify="center" style={{width:'100%', height: '100%'}}>
       <ImageNoMail/>
      </Flex>
      } */}
      { <Flex align="flex-start" justify="flex-start" vertical={true} style={{width:'100%', height: '100%', gap: '24px'}}>
       {(data && data[0].length>0) && <>
        {data[0].map((item: any) => {
          return <SupportRequestCard item={item} />
          })}
          <Pagination onChange={(page)=>{
            setPagination({...pagination,current: page})
          }} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={activeCount} />
          </>
       }
       {(!data || (data && data[1]===0)) &&
         <Flex align="center" justify="center" vertical gap={'24px'} style={{width:'100%', height: '80%'}}>
          <Flex>
            <ImageNoMail/>
          </Flex>
          <Flex style={{fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600,lineHeight: '21.94px',textAlign: 'center'}}>
            Нет заявок в поддержку
          </Flex>
         </Flex>
       }
       </Flex>
      }
      
    <SupoortCreateRequest open={showForm} close={close}/>
    </div>
  );
};
