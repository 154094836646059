// import { Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Badge,Flex, Pagination, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useGetAdminSupportRequestsCountsQuery, useGetAdminSupportRequestsQuery } from "../../../store/supportApiSlice";
import { ImageNoMail } from "../../../Components/Icons/ImageNoMail";
import { SupportRequestCard } from "./SupportRequestCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import './SupportAdmin.scss'

export const SupportAdmin: React.FC = () => {

  const calcCount = (activeTab: string, suportRequesrtsCount: any) => {
    if(!suportRequesrtsCount) return 0
    switch(activeTab){
      case 'all':
        return suportRequesrtsCount.reduce((a: number, item: any)=>{
          a=a + Number(item.count)
          return a
        },0)
      case 'open':
        return suportRequesrtsCount.filter((item: any)=>item.status==='OPEN').reduce((a: number, item: any)=>{
          a=+item.count
          return a
        },0)
      case 'closed':
        return suportRequesrtsCount.filter((item: any)=>item.status==='CLOSED').reduce((a: number, item: any)=>{
          a=+item.count
          return a
        },0)
    }
  }
  const getBadgeColor = (tab: string, activeTab: string, currentAlgorithm: string) => {

    let color = 'var(--Text-text-placeholder, #979AA4)'
    let background = 'var(--background-bg-active, #2B3858)'
    if(tab===activeTab){
      color = 'var(--Text-text-special, #7479F6)'
    }
    return {
      color,
      background,
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '500',
    }
  }

  const params = useParams()
  const [ activeTab, setActiveTab ] = useState<string>(params.page||'all')
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 5,
    current: 1,
  });
  //const { data: createChatData } = useGetCreateChatsOptionsQuery({output_type: params.type});
  const {data} = useGetAdminSupportRequestsQuery({status: activeTab, page: pagination.current, limit: pagination.pageSize})
  const {data: suportRequesrtsCount} = useGetAdminSupportRequestsCountsQuery({})
  
  const items: TabsProps['items'] = [
    { key: 'all', label: <div style={{width: '64px'}}>Все
      {suportRequesrtsCount?<Badge style={{...getBadgeColor('all', activeTab, currentAlgorithm)}} offset={[12,-5]} count={calcCount('all', suportRequesrtsCount)}></Badge>:''}
      </div> },
    { key: 'open', label:  <div style={{width: '100px'}}>Открытые
      {suportRequesrtsCount?<Badge style={{...getBadgeColor('open', activeTab, currentAlgorithm)}} offset={[12,-5]} count={calcCount('open', suportRequesrtsCount)}/>:''}
    </div>},
    { key: 'closed', label: <div style={{width: '100px'}}>Закрытые{suportRequesrtsCount?<Badge style={{...getBadgeColor('closed', activeTab, currentAlgorithm)}} offset={[12,-5]} count={
      calcCount('closed', suportRequesrtsCount)} />:''}
      </div>},
  ];

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }
  let activeCount = calcCount(activeTab, suportRequesrtsCount)

  return (
    <div className={"adminSupportPage "+currentAlgorithm}>
      <Flex style={{width: '100%', alignItems: 'center'}}>
        <Flex style={{width: '100%'}}>
          <Tabs defaultActiveKey={activeTab} onChange={onTabChange} items={items} style={{width: '330px'}}/>
        </Flex>
      </Flex>
      
      { <Flex align="flex-start" justify="flex-start" vertical={true} style={{width:'100%', height: '100%', gap: '24px'}}>
       {(data && data[0].length>0) && <>
        {data[0].map((item: any) => {
          return <SupportRequestCard item={item} />
          })}
          <Pagination onChange={(page)=>{
            setPagination({...pagination,current: page})
          }} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={activeCount} />
          </>
       }
       {(!data || (data && data[1]===0)) &&
         <Flex align="center" justify="center" style={{width:'100%', height: '80%'}}>
         <ImageNoMail/>
         </Flex>
       }       
        </Flex>
       }
    </div>
  );
};
