// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import {
  useGetCreateChatsOptionsQuery,
} from "../../../store/webchatApiSlice";
import { ChatCard } from "./ChatCard";
import { useParams } from "react-router-dom";

export const ChatBotsList: React.FC = () => {
  const params = useParams()
  const { data: createChatData } = useGetCreateChatsOptionsQuery({output_type: params.type});
  return (
    <>
          <ChatCard data={createChatData} parent_id={null} />

    </>
  );
};
