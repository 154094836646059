import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const IconMoon: React.FC = ()=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

return <svg className={"customIcon "+currentAlgorithm} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.42142 0.296819C6.5259 0.42377 6.58991 0.579135 6.60517 0.742838C6.62043 0.906542 6.58625 1.07106 6.50705 1.21514C5.88826 2.35124 5.56511 3.62471 5.56732 4.91839C5.56732 9.22208 9.07578 12.707 13.3998 12.707C13.9639 12.707 14.5129 12.6481 15.0406 12.5357C15.2023 12.5007 15.3708 12.5141 15.525 12.5743C15.6792 12.6344 15.8122 12.7386 15.9075 12.874C16.0081 13.0146 16.0594 13.1846 16.0534 13.3575C16.0474 13.5303 15.9845 13.6964 15.8743 13.8297C15.0349 14.8609 13.9759 15.6917 12.7746 16.2617C11.5732 16.8316 10.2599 17.1262 8.93022 17.1241C3.99612 17.1241 -0.000396729 13.149 -0.000396729 8.25131C-0.000396729 4.56519 2.26222 1.40351 5.48384 0.0634925C5.64432 -0.00433067 5.82247 -0.0182511 5.99154 0.0238224C6.1606 0.0658958 6.31145 0.161691 6.42142 0.296819Z" fill="#2B3858"/>
</svg>
}
