import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string;
};

export const IconLogs: React.FC<PropsType> = ({ className }) => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

let classNames: Array<string> = ["customIcon", currentAlgorithm];
if (className) classNames.push(className);

return <svg className={classNames.join(' ')} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.37086 0.260742H4.62919C4.02252 0.260742 3.52669 0.750742 3.52669 1.35741V1.90574C3.52669 2.51241 4.01669 3.00241 4.62336 3.00241H7.37086C7.97752 3.00241 8.46752 2.51241 8.46752 1.90574V1.35741C8.47336 0.750742 7.97752 0.260742 7.37086 0.260742Z" fill="#EAEAEC"/>
<path d="M9.05669 1.90574C9.05669 2.83324 8.29836 3.59158 7.37086 3.59158H4.62919C3.70169 3.59158 2.94336 2.83324 2.94336 1.90574C2.94336 1.57908 2.59336 1.37491 2.30169 1.52658C1.47919 1.96408 0.919189 2.83324 0.919189 3.83074V9.31991C0.919189 10.7549 2.09169 11.9274 3.52669 11.9274H8.47336C9.90836 11.9274 11.0809 10.7549 11.0809 9.31991V3.83074C11.0809 2.83324 10.5209 1.96408 9.69836 1.52658C9.40669 1.37491 9.05669 1.57908 9.05669 1.90574ZM7.94836 6.51991L5.61502 8.85324C5.52752 8.94074 5.41669 8.98158 5.30586 8.98158C5.19502 8.98158 5.08419 8.94074 4.99669 8.85324L4.12169 7.97824C3.95252 7.80907 3.95252 7.52908 4.12169 7.35991C4.29086 7.19074 4.57086 7.19074 4.74002 7.35991L5.30586 7.92574L7.33002 5.90158C7.49919 5.73241 7.77919 5.73241 7.94836 5.90158C8.11752 6.07074 8.11752 6.35074 7.94836 6.51991Z" fill="#EAEAEC"/>
</svg>
}