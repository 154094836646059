// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { LkChatCard } from "../../../Components/lk/LkChatCard";
import { Card, Flex } from "antd";
import { LastTextGenerations } from "./LastTextGenerations";
import { LastImageGenerations } from "./LastImageGenerations";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import "./LkDashboard.scss"

export const LkDashboard: React.FC = () => {
  const params = useParams();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const { data: createChatData } = useGetCreateChatsOptionsQuery({
    output_type: params.type,
  });

   const dataImages = transformGetCreateChatsOptionsQueryData(createChatData, "image");
  const dataText = transformGetCreateChatsOptionsQueryData(createChatData, "text");

  return (
    <div className={"lkDashBoard " + currentAlgorithm}>
      <Flex style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
        <Flex style={{margin: '12px'}}>
          {dataImages && (
            <Card className="lkCardsContainer" title="Генерация изображений">
              <Flex vertical style={{gap: '24px'}}>
                {dataImages.map((item: any) => {
                  return (
                    <LkChatCard key={item.key} data={item} parent_id={null} />
                  );
                })}
              </Flex>
            </Card>
          )}
        </Flex>
        <Flex style={{margin: '12px'}}>
          {dataText && (
            <Card className="lkCardsContainer" title="Работа с текстом">
              <Flex style={{gap: '24px'}}>
                {dataText.map((item: any) => {
                  return (
                    <LkChatCard key={item.key} data={item} parent_id={null} />
                  );
                })}
              </Flex>
            </Card>
          )}
        </Flex>
      </Flex>
      <Card style={{margin: '12px'}}>
        <LastTextGenerations />
        <LastImageGenerations />
      </Card>
    </div>
  );
};
