import { Select } from "antd"
import React from "react"
import { StatusUserEnum, UserStatusEnumTitles } from "../../types/Users.type"

type PropsType = {
  value: any,
  onChange: (status: StatusUserEnum)=>void
}

const UserStatusSelector: React.FC<PropsType> = (props)=>{
  const keys = Object.keys(StatusUserEnum)
  
  return <>
      <Select value={props.value} onChange={props.onChange}>
        {keys.map(k=>{
          return <Select.Option value={k}>{UserStatusEnumTitles[k]}</Select.Option>

        })}
      </Select>
</>
}

export default UserStatusSelector;