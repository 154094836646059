import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";


type PropsType = {
  className?: string
}

export const IconGenHistory: React.FC<PropsType> = ({className})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);
  
let classNames: Array<string> = ['customIcon', currentAlgorithm]
if(className) classNames.push(className)

return <svg className={classNames.join(' ')} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 0.666626C2.78583 0.666626 0.166667 3.28579 0.166667 6.49996C0.166667 9.71413 2.78583 12.3333 6 12.3333C9.21417 12.3333 11.8333 9.71413 11.8333 6.49996C11.8333 3.28579 9.21417 0.666626 6 0.666626ZM8.5375 8.58246C8.45583 8.72246 8.31 8.79829 8.15833 8.79829C8.0825 8.79829 8.00667 8.78079 7.93667 8.73413L6.12833 7.65496C5.67917 7.38663 5.34667 6.79746 5.34667 6.27829V3.88663C5.34667 3.64746 5.545 3.44913 5.78417 3.44913C6.02333 3.44913 6.22167 3.64746 6.22167 3.88663V6.27829C6.22167 6.48829 6.39667 6.79746 6.5775 6.90246L8.38583 7.98163C8.59583 8.10413 8.66583 8.37246 8.5375 8.58246Z" fill="#2B3858"/>
</svg>
}
