
type BreadcrumbItemType = {
  title: string,
  subtitle?: string,
  test: RegExp|string
}

export const breadcrumbsItems: BreadcrumbItemType[]  = [
      {
        title: 'Пользователи', 
        test: /\/admin\/users/,
      },
      {
        title: 'Логи', 
        test: /\/admin\/logs/,
      },
      {
        title: 'История генераций', 
        test: '/admin/chats',
      },
      {
        title: 'Нейросети / Аккаунты', 
        test: '/admin/neuronets/accounts',  
      },
      {
        title: 'Нейросети / Примеры', 
        test: '/admin/neuronets/examples',  
      },
      {
        title: 'Нейросети / Опции', 
        test: '/admin/neuronets/options',  
      },
      {
        title: 'Заявки в поддержку',
        test: /\/admin\/support/
      },
      {
        title: 'История генераций', 
        subtitle: '',
        test: '/admin/history'
      },  
      {
        title: 'История генераций', 
        subtitle: 'Работа с текстом',
        test: '/admin/history/text'
      },  
      {
        title: 'История генераций', 
        subtitle: 'Генерация изображений',
        test: '/admin/history/images'
      },  
      {
        title: 'Чат', 
        test: 'chat/:id'
      },  
      {
        title: 'Панель управления',
        test: '/lk'
      },
      {
        title: 'Работа с текстом',
        test: '/lk/text'
      },
      {
        title: 'Работа с текстом',
        test: /\/lk\/text\/\d+\/\d+\/\d+/
      },
      {
        title: 'Работа с текстом',
        test: /\/lk\/text\/\d+\/\d+/
      },
      {
        title: 'Генерация изображений',
        test: '/lk/images'
      },
      {
        title: 'Генерация изображений',
        test: /\/lk\/images\/\d+\/\d+/
      },
      {
        title: 'История генераций',
        test: '/lk/history'
      },
      {
        title: 'История генераций',
        subtitle: 'Работа с текстом',
        test: '/lk/history/text'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерация изображений',
        test: '/lk/history/images'
      },
      {
        title: 'Заявки в поддержку',
        test: '/lk/support/all'
      },
      
    ]
 