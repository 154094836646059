import React from "react";
import { User } from "../../types/User.type";
import { StatusUserEnum, UserStatusEnumTitles } from "../../types/Users.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Badge } from "antd";

import './UserStatus.scss'

type PropsType = {
  user?: User
}

export const UserStatus: React.FC<PropsType> = ({user}) => {
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const getUserStatusName = (user: User) => {
    if(!user) return ''
    return UserStatusEnumTitles[user.status]
  }

  const badgeColors = {
      [StatusUserEnum.REG_STARTED]: 'var(--Icon-icon-normal, #7479F6)',
      [StatusUserEnum.PHONE_VERIFIED]: 'var(--Icon-icon-success, #68B274)',
      [StatusUserEnum.ACCOUNT_BANNED]: 'var(--Icon-icon-error, #D83030)',
      [StatusUserEnum.ACCOUNT_DELETED]: 'var(--Icon-icon-error, #D83030)',
      [StatusUserEnum.REG_FINISHED]: 'var(--Icon-icon-success, #68B274)',
      [StatusUserEnum.ACCOUNT_ACCEPTED]: 'var(--Icon-icon-success, #68B274)',
      [StatusUserEnum.ACCOUNT_REJECTED]: 'var(--Icon-icon-error, #D83030)'
  }

  if(!user) return <></>

  const statusName = getUserStatusName(user)

  return <div className={"userStatusComponent "+currentAlgorithm+" "+user.role}><Badge color={badgeColors[user.status]} />{statusName}</div>

}
