import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppLayout from './Components/admin/Layout/Layout';
import OpenLayout from './Components/admin/PublicLayout/OpenLayout';

type PropsType = {
  
}

export const OpenRoute: React.FC<PropsType> = () => {
    return <OpenLayout>
      <Outlet />
    </OpenLayout> 
  };