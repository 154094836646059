import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string
}

export const IconSupport: React.FC<PropsType> = ({className})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);
  
let classNames: Array<string> = ['customIcon', currentAlgorithm]
if(className) classNames.push(className)

  return <svg className={classNames.join(' ')} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 0.666626C2.78583 0.666626 0.166667 3.28579 0.166667 6.49996C0.166667 9.71413 2.78583 12.3333 6 12.3333C9.21417 12.3333 11.8333 9.71413 11.8333 6.49996C11.8333 3.28579 9.21417 0.666626 6 0.666626ZM5.5625 4.16663C5.5625 3.92746 5.76083 3.72913 6 3.72913C6.23917 3.72913 6.4375 3.92746 6.4375 4.16663V7.08329C6.4375 7.32246 6.23917 7.52079 6 7.52079C5.76083 7.52079 5.5625 7.32246 5.5625 7.08329V4.16663ZM6.53667 9.05496C6.5075 9.13079 6.46667 9.18913 6.41417 9.24746C6.35583 9.29996 6.29167 9.34079 6.22167 9.36996C6.15167 9.39913 6.07583 9.41663 6 9.41663C5.92417 9.41663 5.84833 9.39913 5.77833 9.36996C5.70833 9.34079 5.64417 9.29996 5.58583 9.24746C5.53333 9.18913 5.4925 9.13079 5.46333 9.05496C5.43417 8.98496 5.41667 8.90913 5.41667 8.83329C5.41667 8.75746 5.43417 8.68163 5.46333 8.61163C5.4925 8.54163 5.53333 8.47746 5.58583 8.41913C5.64417 8.36663 5.70833 8.32579 5.77833 8.29663C5.91833 8.23829 6.08167 8.23829 6.22167 8.29663C6.29167 8.32579 6.35583 8.36663 6.41417 8.41913C6.46667 8.47746 6.5075 8.54163 6.53667 8.61163C6.56583 8.68163 6.58333 8.75746 6.58333 8.83329C6.58333 8.90913 6.56583 8.98496 6.53667 9.05496Z" fill="#2B3858"/>
</svg>
}