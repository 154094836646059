import { Select } from "antd";
import React from "react";
import './ImageSettingSelect.scss'

type PropsType = {
  data: {
    title: string,
    values: any[],
    default: string,
    onChange: Function
  }[]
}

export const ImageSettingSelector: React.FC<PropsType> = ({ data }) => {
  return (
    <div className="generationImagesMainSettingsBody">
      {data && data.map((el: any) => (
        <div key={el.title} className="generationImagesMainSettingContainer">
          <span className="titleSelect">
            {el.title}
          </span>
          <Select
            defaultValue={el.default}
            onChange={el.onChange}>
            {el.values && el.values.map((val: any) => (
              <Select.Option key={val.value} value={val.value}>{val.title}</Select.Option>
            ))}
          </Select>
        </div>
      ))}
    </div>
  )

} 