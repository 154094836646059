import { apiSlice } from '../api/apiSlice'
import { SaveNeuronetType } from '../types/SaveNeuronet.type'
import { SaveNeuronetOption } from '../types/SaveNeuronetOption.type'
import { SaveNeuronetExample } from '../types/SaveNeuronetExample.type'

export const neuronetsApiSlice = apiSlice.injectEndpoints({

  endpoints: builder => ({    
    getNeuronets: builder.query({
      query: params => ({
        url: '/api/neuronet/list',
        method: 'GET',
        params
      }),
      providesTags: ['Neuronets']
    }),
    getNeurotesTypes: builder.query({
      query: () => ({ url: '/api/neuronet/types' }),
    }),
    getNeurotesOutputTypes: builder.query({
      query: () => ({ url: '/api/neuronet/outputtypes' })
    }),
    getNeuronetOptions: builder.query({
      query: params => ({
        url: '/api/neuronet/options',
        method: 'GET',
        params
      }),
      providesTags: ['NeuronetsOptions']
    }),
    // getNeuronetAbout: builder.query({
    //   query: params => ({
    //     url: '/api/neuronet/about',
    //     method: 'GET',
    //     params
    //   }),
    //   providesTags: ['NeuronetsAbout']
    // }),
    getNeuronetExamples: builder.query({
      query: params => ({
        url: '/api/neuronet/examples',
        method: 'GET',
        params
      }),
      providesTags: ['NeuronetExamples']
    }),
    saveNeuronet: builder.mutation<SaveNeuronetType, Partial<SaveNeuronetType>>({
      query: (params: SaveNeuronetType) => ({
        url: '/api/neuronet',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Neuronets'],
    }),
    saveNeuronetOption: builder.mutation({
      query: (option: SaveNeuronetOption) => ({
        url: '/api/neuronet/option',
        method: 'PUT',
        body: option
      }),
      invalidatesTags: ['NeuronetsOptions']
    }),
    // saveNeuronetAbout: builder.mutation({
    //   query: (about: SaveNeuronetAbout) => ({
    //     url: '/api/neuronet/about',
    //     method: 'PUT',
    //     body: about
    //   }),
    //   invalidatesTags: ['NeuronetsAbout']
    // }),
    saveNeuronetExample: builder.mutation({
      query: (example: SaveNeuronetExample) => ({
        url: '/api/neuronet/example',
        method: 'PUT',
        body: example
      }),
      invalidatesTags: ['NeuronetExamples']
    })
  })
})

export const {
  useGetNeuronetsQuery,
  useGetNeurotesTypesQuery,
  useGetNeurotesOutputTypesQuery,
  useGetNeuronetExamplesQuery,
  useGetNeuronetOptionsQuery,
  useSaveNeuronetMutation,
  useSaveNeuronetExampleMutation,
  useSaveNeuronetOptionMutation
} = neuronetsApiSlice
