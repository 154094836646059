import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string
}

export const IconSpeedtest: React.FC<PropsType> = ({className})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  
  let classNames: Array<string> = ['customIcon', currentAlgorithm]
  if(className) classNames.push(className)

  return <svg className={classNames.join(' ')} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9.1875 12.1667H10.5C11.7887 12.1667 12.8333 11.122 12.8333 9.83337V8.66671C12.8333 5.44505 10.2217 2.83337 7 2.83337C3.77834 2.83337 1.16667 5.44505 1.16667 8.66671V9.83337C1.16667 11.122 2.21134 12.1667 3.5 12.1667H4.8125C4.8125 11.0454 5.65619 10.1211 6.74339 9.9941L8.37484 7.27502C8.49916 7.06782 8.76789 7.00064 8.97509 7.12495C9.18228 7.24927 9.24946 7.51801 9.12515 7.7252L7.70341 10.0948C8.56646 10.3877 9.1875 11.2047 9.1875 12.1667ZM5.6875 12.1667C5.6875 11.4451 6.26991 10.8594 6.99034 10.8542C6.99658 10.8544 7.00281 10.8544 7.00903 10.8542C7.72975 10.8591 8.3125 11.4448 8.3125 12.1667H5.6875ZM4.19836 6.57106C4.74963 5.71917 5.57663 5.02087 7 5.02087C8.42337 5.02087 9.25037 5.71917 9.80164 6.57106C9.93291 6.77392 10.2038 6.83195 10.4066 6.70068C10.6095 6.56941 10.6675 6.29854 10.5362 6.09569C9.86719 5.06178 8.79216 4.14587 7 4.14587C5.20785 4.14587 4.13281 5.06178 3.46376 6.09569C3.33249 6.29854 3.39052 6.56941 3.59337 6.70068C3.79623 6.83195 4.06709 6.77392 4.19836 6.57106Z" fill="#2B3858"/>
  </svg>
}