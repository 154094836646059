import { apiSlice } from '../api/apiSlice'

export const logsApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLogs: builder.query({
            query: (params) => ({
                url: '/api/log/user/list',
                method: 'GET',
                params
            })
        }),
        
    })
})

export const {useGetLogsQuery} = logsApi