import { User } from "./User.type"

export type UsersQueryParams = {
    page: number,
    limit: number,
    q: string
}

export type UsersQueryResult = {
    data: [User],
    lastPage: number,
    page: number,
    perPage: number,
    total: number
}

export enum StatusUserEnum {
    REG_STARTED= 'REG_STARTED', // Пользователь создан через админ панель но он еще не подтвердил номер телефона
    PHONE_VERIFIED= 'PHONE_VERIFIED', // Подтвержден номер телефона
    ACCOUNT_BANNED= 'ACCOUNT_BANNED', // Аккаунт был забанен
    ACCOUNT_DELETED= 'ACCOUNT_DELETED', // Аккаунт удален
    REG_FINISHED='REG_FINISHED',
    ACCOUNT_ACCEPTED='ACCOUNT_ACCEPTED',
    ACCOUNT_REJECTED='ACCOUNT_REJECTED'
  }

export const UserStatusEnumTitles: {[key: string]: string } = {
    [StatusUserEnum.REG_STARTED]: 'Начало регистрации',
    [StatusUserEnum.PHONE_VERIFIED]: 'Телефон подтвержден',
    [StatusUserEnum.ACCOUNT_BANNED]: 'Аккаунт заблокирован',
    [StatusUserEnum.ACCOUNT_DELETED]: 'Аккаунт удален',
    [StatusUserEnum.REG_FINISHED]: 'Регистрация завершена',
    [StatusUserEnum.ACCOUNT_ACCEPTED]: 'Аккаунт принят',
    [StatusUserEnum.ACCOUNT_REJECTED]: 'Аккаунт отклонен'
}