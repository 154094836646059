import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/login/Login';
import Dashboard from './pages/admin/dashboard/Dashboard';
import Users from './pages/admin/users/Users';
import { ProtectedRoute } from './ProtectedRoute';
import Logs from './pages/admin/logs/Logs';
import UserView from './pages/admin/users/UserView';

import { Page404 } from './pages/public/404/page404';
import { OpenRoute } from './OpenRoute';
import { NeuronetsMain } from './pages/admin/neuronets/NeuronetsMain';
import { ChatBotsList } from './pages/admin/chatbots/ChatBotsList';
import { ChatbotChat } from './pages/admin/chatbots/ChatbotChat';
import { NeuronetOptions } from './pages/admin/chatbots/NeuronetOptions';
import { MainPage } from './pages/public/main/main';
import { LkDashboard } from './pages/lk/dashboard/LkDashboard';
import { LkProtectedRoute } from './LkProtectedRoute';
import { useGetCurrentUserQuery } from './store/userApiSlice';
import { History } from './pages/lk/history/History';
import { Support } from './pages/lk/support/Support';
import { LkImagesChatList } from './pages/lk/images/LkImagesChatList';
import { LkTextChatsList } from './pages/lk/text/LkTextChatsList';
import { LkChatText } from './pages/lk/chat/LkChatText';
import { GenerationImages } from './pages/lk/images/GenerationImages';
import { SupportAdmin } from './pages/admin/support/SupportAdmin';
import { AdminHistory } from './pages/admin/history/AdminHistory';


const App: React.FC = () => {
  const {data: user, isLoading} = useGetCurrentUserQuery({})
  
  return <Routes>
    <Route path='/login' element={<Login />} />
    <Route path='' element={<OpenRoute />}>
      <Route path='' element={<MainPage />} />      
    </Route>

    <Route path='admin' element={<ProtectedRoute user={user} isLoading={isLoading} />}>      
        <Route path='' element={<Dashboard />} />
        <Route path='users' element={<Users />} />
        <Route path='user/:id' element={<UserView />} />
        <Route path='logs' element={<Logs />} />
        {/* <Route path='chats' element={<ChatList />} /> */}
        {/* <Route path='chat/:id' element={<WebChat />} /> */}
        <Route path='neuronets' element={<NeuronetsMain />} />
        <Route path='neuronets/:source' element={<NeuronetsMain />} />
        <Route path='chatbots' element={<ChatBotsList />} />
        <Route path='neuronet/:type/:neuronet_id' element={<NeuronetOptions />} />
        <Route path='neuronet/:type/:neuronet_id/:option_id' element={<ChatbotChat />} />
        <Route path='neuronet/:type/:neuronet_id/:option_id/:chat_id' element={<ChatbotChat />} />
        <Route path='support' element={<SupportAdmin />} />
        <Route path='history' element={<AdminHistory />} />
        <Route path='history/:type' element={<AdminHistory />} />
        

    </Route>
    
    <Route path='/lk' element={<LkProtectedRoute user={user} isLoading={isLoading}/>}>
      <Route path='' element={<LkDashboard />} />
      <Route path='text' element={<LkTextChatsList />} />
      <Route path='text/:neuronet_id/:option_id' element={<LkChatText />} />
      <Route path='text/:neuronet_id/:option_id/:chat_id' element={<LkChatText />} />
      <Route path='images' element={<LkImagesChatList />} />
      <Route path='images/:neuronet_id/:option_id' element={<GenerationImages />} />
      <Route path='images/:neuronet_id/:option_id/:chat_id' element={<GenerationImages />} />
      
      <Route path='history' element={<History />} />
      <Route path='history/:type' element={<History />} />
      <Route path='support/:page' element={<Support />} />
      
    </Route>
    <Route path='*' element={<Page404 />} /> 
  </Routes>



}

export default App;
