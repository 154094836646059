import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconCross: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)

    return <svg className={classNames.join(' ')} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8047 5.14299C11.9261 5.01726 11.9933 4.84886 11.9918 4.67406C11.9903 4.49926 11.9202 4.33205 11.7966 4.20845C11.673 4.08484 11.5057 4.01473 11.3309 4.01321C11.1561 4.01169 10.9877 4.07889 10.862 4.20033L8.00001 7.06233L5.13801 4.20033C5.01228 4.07889 4.84388 4.01169 4.66908 4.01321C4.49428 4.01473 4.32707 4.08484 4.20347 4.20845C4.07986 4.33205 4.00975 4.49926 4.00823 4.67406C4.00671 4.84886 4.07391 5.01726 4.19535 5.14299L7.05735 8.00499L4.19535 10.867C4.13167 10.9285 4.08089 11.0021 4.04595 11.0834C4.01101 11.1647 3.99262 11.2522 3.99185 11.3407C3.99108 11.4292 4.00794 11.517 4.04147 11.599C4.07499 11.6809 4.12449 11.7553 4.18708 11.8179C4.24968 11.8805 4.32411 11.93 4.40604 11.9635C4.48797 11.9971 4.57576 12.0139 4.66428 12.0132C4.7528 12.0124 4.84028 11.994 4.92162 11.9591C5.00295 11.9241 5.07652 11.8733 5.13801 11.8097L8.00001 8.94766L10.862 11.8097C10.9877 11.9311 11.1561 11.9983 11.3309 11.9968C11.5057 11.9953 11.673 11.9251 11.7966 11.8015C11.9202 11.6779 11.9903 11.5107 11.9918 11.3359C11.9933 11.1611 11.9261 10.9927 11.8047 10.867L8.94268 8.00499L11.8047 5.14299Z" 
        fill="#2B3858" />
    </svg>
}