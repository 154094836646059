import React from "react";
import "./LkChatCard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";

type PropsType = {
  data: any
  parent_id: number|null
};

export const LkChatCard: React.FC<PropsType> = ({ data, parent_id }) => {
  const navigate = useNavigate()
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const onclick = ()=>{
    const {neuronet_id, option_id, output_type} = data
    if(output_type==='text') navigate(`/lk/text/${neuronet_id}/${option_id}`)
    if(output_type==='image') navigate(`/lk/images/${neuronet_id}/${option_id}`)
  }

  return (
    <>
      <div key={data.id} className={"lkchatcard "+currentAlgorithm} onClick={onclick}>
        <div className="lkchatCardContainer">
          <div className="lkchatCardLogo" style={{
            backgroundImage: `url(${data.image?.url})`}
          }>
          {/* {data.image && <img src={data.image?.url} style={{width: '100px'}}></img>} */}
            </div>
          <div className="lkchatCardTitle">{data.name}</div>
          {/* <div className="lkchatCardTitle">{JSON.stringify(data)}</div> */}
        </div>
      </div>

    </>
  );
};

