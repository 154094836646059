import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  fill?: string
}

export const IconPlus: React.FC<PropsType> = ({fill})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);


return <svg className={"plusIcon "+currentAlgorithm} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.41667 9.08354C4.41667 9.23824 4.47813 9.38662 4.58752 9.49601C4.69692 9.60541 4.84529 9.66687 5 9.66687C5.15471 9.66687 5.30309 9.60541 5.41248 9.49601C5.52188 9.38662 5.58334 9.23824 5.58334 9.08354V5.58353H9.08334C9.23805 5.58353 9.38642 5.52208 9.49582 5.41268C9.60521 5.30328 9.66667 5.15491 9.66667 5.0002C9.66667 4.84549 9.60521 4.69712 9.49582 4.58772C9.38642 4.47833 9.23805 4.41687 9.08334 4.41687H5.58334V0.916868C5.58334 0.762158 5.52188 0.613785 5.41248 0.504389C5.30309 0.394993 5.15471 0.333534 5 0.333534C4.84529 0.333534 4.69692 0.394993 4.58752 0.504389C4.47813 0.613785 4.41667 0.762158 4.41667 0.916868V4.41687H0.916669C0.76196 4.41687 0.613587 4.47833 0.50419 4.58772C0.394794 4.69712 0.333336 4.84549 0.333336 5.0002C0.333336 5.15491 0.394794 5.30328 0.50419 5.41268C0.613587 5.52208 0.76196 5.58353 0.916669 5.58353H4.41667V9.08354Z" 
fill={fill || "#7479F6"}/>
</svg>
}