import React, { useState } from "react";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Layout, Button, theme, Row, Col, Flex } from "antd";
import AppMenu from "../Menu/Menu";
import { Link, useLocation } from "react-router-dom";
import AvatarContainer from "./AvatarContainer";
import { toggleAlgorithm } from "../../../store/designModeSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { MenuItemSrcType } from "../Menu/MenuItems";
import { logout } from "../../../store/authSlice";
import { apiSlice } from "../../../api/apiSlice";
import { IconMoon } from "../../Icons/IconMoon";
import { IconLightTheme } from "../../Icons/IconLightTheme";
import { IconLogout } from "../../Icons/IconLogout";
import { BreadcrumbsContainer } from "./Breadcrumbs";

import "./Layout.scss";

const { Header, Sider, Content } = Layout;

type PropsType = {
  menuItems: MenuItemSrcType[];
};

const AppLayout: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  menuItems,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  
  const logoutClick = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
  };
  return (
    <Flex>
      <Layout className="layoutStyle">
        <Sider
          className={"leftSlider " + currentAlgorithm}
          width={"228px"}
          trigger={null}
          collapsible
          collapsed={collapsed}>
          <div className="logoVertical">
            <span className={"logo_image "+(collapsed?'collapsed':'')}>
              <Link to="/">
                <img src="/logo.svg" />
              </Link>
            </span>
            {!collapsed && (
              <span className="logo_title">
                <Link to="/">Нейротех</Link>
              </span>
            )}
            {/* {!collapsed && ( */}
            <span className="logoBtnContainer">
              <Button
                type="primary"
                icon={
                  collapsed ? <RightOutlined height={'8px'} width={'4px'} /> : <LeftOutlined height={'8px'} width={'4px'}/>
                }
                onClick={() => setCollapsed(!collapsed)}
              />
            </span>
            {/* )} */}
          </div>
          <div>
            <AppMenu collapsed={collapsed} menuItems={menuItems} />
          </div>
          <div>
            <span className="logoutbtn_container" onClick={logoutClick}>
              <IconLogout />
              {!collapsed && 
              <span className="logoutbtn_title">Выход</span>
              }
            </span>
          </div>
        </Sider>
        <Layout>
          <Header
            className={"headerStyle " + currentAlgorithm}>
            <Row>
              <Col span={12}>
                <div className="topLeftPanel">
                  <BreadcrumbsContainer />
                </div>
              </Col>

              <Col span={12}>
                <div className="topRightPanel">
                  <span className="darkModeSwith">
                    <span className="toggleAlgorithmBtn"
                      onClick={() => dispatch(toggleAlgorithm({}))}
                      style={{ marginRight: "8px" }}>
                          {currentAlgorithm==='light'?<IconMoon />:<IconLightTheme />}
                    </span>
                  </span>
                  <AvatarContainer />
                </div>
              </Col>
            </Row>
          </Header>
          <Content
            className={"contentStyle " + currentAlgorithm}
            style={{
              padding: 24,
              minHeight: 280,
            }}>
            <>{children}</>
          </Content>
        </Layout>
      </Layout>
    </Flex>
  );
};

export default AppLayout;
