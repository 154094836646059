import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string;
};

export const IconEdit: React.FC<PropsType> = ({ className }) => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  let classNames: Array<string> = ["customIcon", currentAlgorithm];
  if (className) classNames.push(className);

  return (
    <svg
      className={classNames.join(' ')}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 12.8331H1.75C1.51083 12.8331 1.3125 12.6348 1.3125 12.3956C1.3125 12.1564 1.51083 11.9581 1.75 11.9581H12.25C12.4892 11.9581 12.6875 12.1564 12.6875 12.3956C12.6875 12.6348 12.4892 12.8331 12.25 12.8331Z"
        fill="#2B3858"
      />
      <path
        d="M11.095 2.02977C9.96333 0.8981 8.855 0.868933 7.69417 2.02977L6.98833 2.7356C6.93 2.79393 6.90667 2.88727 6.93 2.96893C7.37333 4.51477 8.61 5.75143 10.1558 6.19477C10.1792 6.2006 10.2025 6.20643 10.2258 6.20643C10.29 6.20643 10.3483 6.1831 10.395 6.13643L11.095 5.4306C11.6725 4.85893 11.9525 4.30477 11.9525 3.74477C11.9583 3.16727 11.6783 2.60727 11.095 2.02977Z"
        fill="#2B3858"
      />
      <path
        d="M9.10583 6.7256C8.93667 6.64393 8.77333 6.56227 8.61583 6.46893C8.4875 6.3931 8.365 6.31143 8.2425 6.22393C8.14333 6.15977 8.02667 6.06643 7.91583 5.9731C7.90417 5.96727 7.86333 5.93227 7.81667 5.8856C7.62417 5.72227 7.40833 5.51227 7.21583 5.27893C7.19833 5.26727 7.16917 5.22643 7.12833 5.17393C7.07 5.10393 6.97083 4.98727 6.88333 4.8531C6.81333 4.7656 6.73167 4.63727 6.65583 4.50893C6.5625 4.35143 6.48083 4.19393 6.39917 4.0306C6.38681 4.00412 6.37485 3.97777 6.36326 3.95158C6.27717 3.75714 6.02363 3.7003 5.87327 3.85066L2.53167 7.19227C2.45583 7.2681 2.38583 7.41393 2.36833 7.5131L2.05333 9.74727C1.995 10.1439 2.10583 10.5173 2.35083 10.7681C2.56083 10.9723 2.8525 11.0831 3.1675 11.0831C3.2375 11.0831 3.3075 11.0773 3.3775 11.0656L5.6175 10.7506C5.7225 10.7331 5.86833 10.6631 5.93833 10.5873L9.27577 7.24983C9.42717 7.09843 9.37005 6.83857 9.17312 6.75454C9.15095 6.74508 9.12854 6.73544 9.10583 6.7256Z"
        fill="#2B3858"
      />
    </svg>
  );
};
