import { Button, Card, Tooltip } from "antd";
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

//import "./ChatCard.scss";

type PropsType = {
  id: any;
  option_id: number
  name: string
  about: string
  type: string
  // onClick?: (id: number, option_id?: number|null|undefined) => void;
};

export const NeuronetCard: React.FC<PropsType> = ({
  id,
  option_id,
  name,
  about,
  type,
  // onClick,
}) => {
  const navigate = useNavigate();

  const addChat = () => () => {
    navigate(`/chatbot/${type}/${id}/${option_id}`)

    // if(onClick){
    //   onClick(id, option_id);
    // }
  };

  return (
    <>
      <Card style={{ width: "240px" }} title={name}>
        <div className="chatListContainer">
          <div className="chatListText">{about}</div>
          <div className="chatListAddBtn">
            <Tooltip title="">
              <Button
              type="primary"
                shape="circle"
                onClick={addChat()}
                icon={<PlusCircleOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
    </>
  );
};
