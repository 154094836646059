import { apiSlice } from "../api/apiSlice";
import { io } from "socket.io-client";
import { RootState } from "./store";

export const chatsApiSlice = apiSlice
.injectEndpoints({
  endpoints: builder => ({
    getChats: builder.query({
      query: params => ({
        url: '/api/webchat/list',
        params
      }),
      providesTags: ['Chats'],
    }),
    getAllChats: builder.query({
      query: params => ({
        url: '/api/webchat/admin/list',
        params
      }),
      providesTags: ['Chats'],
    }),
    getImagesPreview: builder.query({
      query: params => ({
        url: '/api/webchat/images/preview',
        params
      }),
      // transformResponse(response: [ChatType[], number]) {
      //   return chatsAdapter.addMany(
      //     chatsAdapter.getInitialState(),
      //     response[0]
      //   )
      // },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
      ) {

        
        // create a websocket connection when the cache subscription starts
        const token= (getState() as RootState).auth.token
        const ws = io('/',{
          autoConnect: false,
          transports: ["websocket", "polling"],
          withCredentials:true,
          auth:{
            Authorization: `Bearer ${token}`
          }
        })

        await cacheDataLoaded
        const listener = (event: any) => {
          dispatch(apiSlice.util.invalidateTags(['Chats']))
        }
        ws.on('error',  (e) => {
          console.error(e)
        })

        ws.on('message', listener)
        
        ws.connect()

        await cacheEntryRemoved
        ws.close()
      },  
      providesTags: ['Chats'] 
    }), 
    getAllImagesPreview: builder.query({
      query: params => ({
        url: '/api/webchat/admin/images/preview',
        params
      }),
      // transformResponse(response: [ChatType[], number]) {
      //   return chatsAdapter.addMany(
      //     chatsAdapter.getInitialState(),
      //     response[0]
      //   )
      // },
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
      ) {

        
        // create a websocket connection when the cache subscription starts
        const token= (getState() as RootState).auth.token
        const ws = io('/',{
          autoConnect: false,
          transports: ["websocket", "polling"],
          withCredentials:true,
          auth:{
            Authorization: `Bearer ${token}`
          }
        })

        await cacheDataLoaded
        const listener = (event: any) => {
          dispatch(apiSlice.util.invalidateTags(['Chats']))
        }
        ws.on('error',  (e) => {
          console.error(e)
        })

        ws.on('message', listener)
        
        ws.connect()

        await cacheEntryRemoved
        ws.close()
      },  
      providesTags: ['Chats'] 
    }), 
    getTextPreview: builder.query({
      query: params => ({
        url: '/api/webchat/text/preview',
        params
      }),
      providesTags: ['MessagesWithReply']
    }), 

    getCreateChatsOptions: builder.query({
      query: params => ({
        url: '/api/neuronet/chats/creation',
        params,
      }),
      providesTags: ['Neuronets'],
    }),
    getChatMessagesList: builder.query({
      query: params => ({
        url: `/api/webchat/${params.chat_id}/messages`
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
      ) {
        const token= (getState() as RootState).auth.token
        const ws = io('/',{
          autoConnect: false,
          transports: ["websocket", "polling"],
          withCredentials:true,
          auth:{
            Authorization: `Bearer ${token}`
          }
        })
        try {
          await cacheDataLoaded

          const listener = (event: MessageEvent) => {
            dispatch(apiSlice.util.invalidateTags(['ChatMessages']))

          }
          ws.on('error',  (e) => {
            console.error(e)
          })

          ws.on('message', listener)
          
          ws.connect()
        } catch(e) {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
          console.error(e)
        }
        await cacheEntryRemoved
        ws.close()
      },      
      providesTags: ['ChatMessages']
    }),
    // createChats: builder.mutation({
    //   query: params =>({
    //     url: '/api/webchat/create',
    //     method: 'POST',
    //     body: {...params}
    //   }),
    //   invalidatesTags: ['Chats']
    // }),
    deleteChat: builder.mutation({
      query: params => ({
        url: `/api/webchat/${params.chat_id}`,
        method: "DELETE"
      }),
      invalidatesTags: ['ChatMessages', 'Chats', 'MessagesWithReply']
    }),
    sendMessage: builder.mutation({
      query: params => ({
        url: `/api/webchat/${params.chat_id}/message`,
        method: 'POST',
        body: {...params, chat_id: undefined}
      }),
      invalidatesTags: ['ChatMessages', 'Chats', 'MessagesWithReply']
    }),
    toggleFavorite: builder.mutation({
      query: params => ({
        url: `/api/webchat/favorites/toggle/${params.chat_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ChatMessages', 'Chats', 'MessagesWithReply']
    }), 


  })

})

export const {
  useGetChatsQuery,
  useGetAllChatsQuery,
  useGetImagesPreviewQuery,
  useGetAllImagesPreviewQuery,
  useGetTextPreviewQuery,
  useGetCreateChatsOptionsQuery,
  useGetChatMessagesListQuery,
  useSendMessageMutation,
  // useCreateChatsMutation,
  useDeleteChatMutation,
  useToggleFavoriteMutation
} = chatsApiSlice

export const transformGetCreateChatsOptionsQueryData = (createChatData: any, type?: string) => {
  if (createChatData) {
    return createChatData
      .filter((item: any) => {
        if(!type) return true 
        else return item.output_type === type
      })
      .map((item: any) => {
        if (item.options && item.options.length > 0) {
          return [
            {
              ...item,
              neuronet_id: item.id,
              option_id: 0,
              key: ''+item.id
            },
            ...item.options.map((option: any) => {
              return { 
                ...option, 
                neuronet_id: item.id,
                output_type: item.output_type,
                option_id: option.id,
                key: item.id+'_'+option.id
              };
            }),
          ];
        }
        return {
          ...item,
          neuronet_id: item.id,
          option_id: 0,
          key: ''+item.id
        };
      })
      .flat();
  }
}

export const getChatByIdAndOptionId = (createChatData: any, neuronet_id: any, option_id: any) => {
  if(!createChatData) return null
  return createChatData.reduce((a: any, neuronet: any)=>{
    if(neuronet.id===Number.parseInt(neuronet_id) && Number.parseInt(option_id)===0) a={...neuronet}
    // eslint-disable-next-line eqeqeq
    if(neuronet.id===Number.parseInt(neuronet_id) && Number.parseInt(option_id)!==0) a={...neuronet.options.find((o: any)=>o.id == option_id)}
    return a
  }, {})
}