import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, GetProp, message, Upload } from "antd";
import { UploadProps } from "antd/lib";
import React, { useState } from "react";
import { useAppSelector } from "../../../store/store";

import "./UserAvatarUpload.scss"
import { ImageSelector } from "../../../Components/admin/ImageSelector/ImageSelector";
import { ImageType } from "../../../types/Image.type";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

type PropsType = {
  value?: { id: number, url: string}
  onUploadComplete: (data: any) => void
}
// const getBase64 = (img: FileType, callback: (url: string) => void) => {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result as string));
//   reader.readAsDataURL(img);
// };

export const UserAvatarUpload: React.FC<PropsType> = ({value, onUploadComplete}) => {
  const token = useAppSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(value?.url);

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const img = info.file.response.find((i: any) => true);
      setLoading(false);
      setImageUrl(img.url);
      onUploadComplete(img)
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
      <div style={{ marginTop: 8 }}>Ширина 100px</div>
    </button>
  );

  const selectImage= (image: ImageType)=>{
    onUploadComplete(image)
    setImageUrl(image.url)
  }

  return (

    <ImageSelector onSelect={selectImage} folder="avatar_images">
       {imageUrl ? (
        <img src={imageUrl} alt="user avatar" style={{ width: "100px" }} />
      ) : (
        uploadButton
      )}
    </ImageSelector>

  );
};
