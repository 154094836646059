import { apiSlice } from "../api/apiSlice";
import { ImageType } from "../types/Image.type";
import { Paginated } from "../types/Paginated";

export const imagesApiSlice = apiSlice
.enhanceEndpoints({
  addTagTypes: ['Images']
})
.injectEndpoints({
  endpoints: builder => ({
    getImages: builder.query<Paginated<ImageType>, {page: number, limit: number}>({
      query: params => ({
        url: '/api/image/list',
        params
      }),
      providesTags: ['Images'],
    }),
  })
})

export const {
  useGetImagesQuery
} = imagesApiSlice