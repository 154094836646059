import { Tag } from "antd";
import React from "react";
import { User } from "../../types/User.type";
import { UserRoleEnum } from "../../types/UserRole.enum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import './RoleTag.scss'

type PropsType = {
  user?: User
}

export const RoleTag: React.FC<PropsType> = ({user}) => {
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const getRoleName = (role: UserRoleEnum) => {
    if (role===UserRoleEnum.Member) return "Участник"
    if (role===UserRoleEnum.Admin) return "Админ"
    if (role===UserRoleEnum.SuperAdmin) return "Супер админ"
  }
  const getRoleColor = (role: UserRoleEnum) => {
    if (role===UserRoleEnum.Member) return "Участник"
    if (role===UserRoleEnum.Admin) return "Админ"
    if (role===UserRoleEnum.SuperAdmin) return "Супер админ"
  }

  if(!user) return <></>

  const roleText = getRoleName(user.role)

  return <div className={"roleTag "+currentAlgorithm+" "+user.role}>{roleText}</div>

}
