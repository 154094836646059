import React, { useEffect } from "react";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { useNavigate } from "react-router-dom";
import { useGetCurrentUserQuery } from "../../../store/userApiSlice";

export const MainPage: React.FC = ()=>{
  const navigate = useNavigate();
  // const auth = useSelector(getToken);
  const {data: user, isLoading} = useGetCurrentUserQuery({})

  const redirectOnAuth = (userData: any) => {
    if (userData) {
      if (userData.user.role === UserRoleEnum.SuperAdmin) {
        navigate("/admin/users");
      } else if (userData.user.role === UserRoleEnum.Admin) {
        navigate("/admin/users");
      } else if (userData.user.role === UserRoleEnum.Member) {
        navigate("/lk");
      }
    }
  };

  useEffect(()=>{
    if(!isLoading && user){
       redirectOnAuth({user})
    } else if(!isLoading && !user) {
      navigate('/login')
    }
  }, [user, isLoading])


  return <>
    
  </>
}