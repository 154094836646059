import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { NotificationMeaasge } from "../types/NotificationMessage"



interface NotificationType {
    messages: Array<NotificationMeaasge>
    next_id: number
}

const initialState: NotificationType = {
        messages: [],
        next_id: 1
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers:{
        showNotification: (state, action: PayloadAction<NotificationMeaasge>) => {
            const message: NotificationMeaasge = Object.assign({id: state.next_id, status: 'new', type: 'info'}, action.payload)
            state.messages.push(message)
            state.next_id += 1
            
        },
        setStatus: (state, action) => {
            state.messages[state.messages.findIndex(msg=>msg.id === action.payload.id)].status = action.payload.status
        },
        delMessage: (state, action) =>{
            
            state.messages.splice(state.messages.findIndex(msg=>msg.id === action.payload.message.id),1)
        },
    }
})

export const { showNotification, delMessage, setStatus } = notificationSlice.actions

export default notificationSlice.reducer