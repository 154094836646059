import { apiSlice } from "../api/apiSlice";

export const supportApiSlice = apiSlice
.enhanceEndpoints({
  addTagTypes: ['SupportRequests']
})
.injectEndpoints({
  endpoints: builder => ({
    getSupportRequests: builder.query({
      query: params => ({
        url: '/api/support/list',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
    getSupportRequestsCounts: builder.query({
      query: params => ({
        url: '/api/support/counts',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
    createRequest: builder.mutation({
      query: params =>({
        url: '/api/support/create',
        method: 'POST',
        body: {
          ...params
        }
      }),
      invalidatesTags: ['SupportRequests']
    }),
    getAdminSupportRequests: builder.query({
      query: params => ({
        url: '/api/support/admin/list',
        params
      })
    }),
    getAdminSupportRequestsCounts: builder.query({
      query: params => ({
        url: '/api/support/admin/counts',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
  })
})

export const {
  useCreateRequestMutation,
  useGetSupportRequestsQuery,
  useGetSupportRequestsCountsQuery,
  useGetAdminSupportRequestsQuery,
  useGetAdminSupportRequestsCountsQuery
} = supportApiSlice