export enum UserRoleEnum {
    SuperAdmin = 'superadmin',
    Admin = 'admin',
    Member = 'member'
  }

  export const UserRoleEnumTitles: {[key: string]: string } = {
    [UserRoleEnum.Admin]: 'Администратор',
    [UserRoleEnum.SuperAdmin]: 'Супер админ',
    [UserRoleEnum.Member]: 'Участник'
  }

  