import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const IconLogout: React.FC = ()=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

return <svg className={"customIcon "+currentAlgorithm} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.72929 0.166626C6.27139 0.166626 6.71088 0.578993 6.71088 1.08768C6.71088 1.59636 6.27139 2.00873 5.72929 2.00873H2.45735C2.27665 2.00873 2.13016 2.14619 2.13016 2.31575V9.68417C2.13016 9.8537 2.27665 9.99119 2.45735 9.99119H5.4021C5.94419 9.99119 6.38368 10.4036 6.38368 10.9122C6.38368 11.4209 5.94419 11.8333 5.4021 11.8333H2.45735C1.19242 11.8333 0.166992 10.8711 0.166992 9.68417V2.31575C0.166992 1.12882 1.19242 0.166626 2.45735 0.166626H5.72929ZM9.69528 3.61192L11.5462 5.34865C11.9295 5.70835 11.9295 6.29156 11.5462 6.65127L9.69528 8.388C9.31194 8.7477 8.69047 8.7477 8.30713 8.388C7.92379 8.0283 7.92379 7.44515 8.30713 7.08545L8.48231 6.92101H5.72929C5.1872 6.92101 4.74771 6.50863 4.74771 5.99996C4.74771 5.49129 5.1872 5.07891 5.72929 5.07891H8.48231L8.30713 4.91447C7.92379 4.5548 7.92379 3.97162 8.30713 3.61192C8.69047 3.25223 9.31194 3.25223 9.69528 3.61192Z" fill="#2B3858"/>
</svg>
}