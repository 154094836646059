import { apiSlice } from "../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials =>({
        url: '/api/auth/authorise',
        method: 'POST',
        body: {...credentials}
      })
    }),
    loginByTgUser: builder.mutation({
      query: tguser =>({
        url: '/api/auth/telegram/login',
        method: 'POST',
        body: {...tguser}
      })
    })
  })
})

export const { useLoginMutation, useLoginByTgUserMutation } = authApiSlice