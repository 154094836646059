import { Select } from "antd"
import FormItem from "antd/es/form/FormItem"
import { Option } from "antd/es/mentions"
import React from "react"
import { UserRoleEnum } from "../../types/UserRole.enum"
import { useGetNeurotesOutputTypesQuery, useGetNeurotesTypesQuery } from "../../store/neuronetApiSlice"

type PropsType = {
  value: any,
  onChange: (role: UserRoleEnum)=>void
}

const NeuronetOutputTypeSelector: React.FC<PropsType> = (props)=>{

    const {data, isLoading } = useGetNeurotesOutputTypesQuery({})
    
    if(isLoading){
        return <>Loading...</>
    }
    if(!data) {
        return <>No data</>
    }
    const keys = Object.keys(data)

  return <>

      <Select value={props.value} onChange={props.onChange}>
        {keys.map(k=>
            <Select.Option value={data[k]}>{k}</Select.Option>
        )}
      </Select>
</>
}

export default NeuronetOutputTypeSelector;