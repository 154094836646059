import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const defaultActivePage = {
    title: 'Главная',
    subtitle: null
}

const defaultActiveMenuItem = {
    key: '/lk',
}

const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState: {
        activePage: defaultActivePage,
        activeMenuItem: defaultActiveMenuItem
    },
    reducers:{
        setActivePage: (state, action)=>{
            state.activePage = action.payload           
        },
        setActiveMenuItem: (state, action)=>{
            state.activeMenuItem = action.payload           
        }
    }
})

export const { setActivePage, setActiveMenuItem } = layoutSlice.actions

export default layoutSlice.reducer

export const getActivepage = (state: RootState) => state.layoutSlice.activePage
export const getActiveMenuItem = (state: RootState) => state.layoutSlice.activeMenuItem
