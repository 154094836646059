import { Form, Input, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useState } from "react";
import NeuronetTypeSelector from "../../../Components/common/NeuronetTypeSelector";
import TextArea from "antd/es/input/TextArea";
import { SaveNeuronetExample } from "../../../types/SaveNeuronetExample.type";

type PropsType = {
  editedItem: SaveNeuronetExample
  save: (data: SaveNeuronetExample)=>Promise<boolean>
  closeModal: ()=>void
  modalVisible: boolean
}

export const NeuronetExampleEditor: React.FC<PropsType> = ({editedItem, save, closeModal, modalVisible}) => {
  const [editedItemLocal, setEditedItemLocal] = useState<SaveNeuronetExample>(editedItem)
  const ok = ()=>{
    save(editedItemLocal).then(e=>{
      if (e) closeModal()
    })
    
  }
  const cancel = ()=>{
    closeModal()
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  
  return <>
  <Modal 
    getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
    title="Редактирование нейросети"
        wrapClassName="vertical-center-modal"
        open={modalVisible}
        onOk={() => ok()}
        onCancel={() => cancel()}>
        <Form>

          {JSON.stringify(editedItemLocal)}
{/* 
          <FormItem
                      {...formItemLayout}
                      label={
                        <span>
                          Название&nbsp;
                        </span>
                      }
                      hasFeedback>
                      <Input value={editedItemLocal.name} onChange={(val)=>setEditedItemLocal({...editedItemLocal, name: val.target.value})}/>
          </FormItem> */}
          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Тип&nbsp;
                        </span>
                      }
                      hasFeedback>
            <NeuronetTypeSelector value={editedItemLocal.type} 
              onChange={(val)=>setEditedItemLocal({...editedItemLocal, type: val})} />
          </FormItem>
          <FormItem {...formItemLayout}
                      label={
                        <span>
                          Пример&nbsp;
                        </span>
                      }
                      hasFeedback>
            <TextArea rows={4} 
              value={editedItemLocal.example_text} 
              onChange={(val)=>setEditedItemLocal({...editedItemLocal, example_text: val.target.value})} />
          </FormItem>
        </Form>
  </Modal>
  </>
}