// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import {
  useGetCreateChatsOptionsQuery
} from "../../../store/webchatApiSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "./NeuronetOptions.scss";
import { NeuronetCard } from "./NeuronetCard";

export const NeuronetOptions: React.FC = () => {
  const params = useParams();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const chat_type = params.type||'text'
  const chat_id = params.chat_id
  const option_id = params.option_id

  
  const { data } = useGetCreateChatsOptionsQuery({ id: chat_id });
  // eslint-disable-next-line eqeqeq
  const chatbot = data
    ? data.find((chatbot: any) => chatbot.id == chat_id)
    : undefined;

  
  return (
    <>
      {chatbot && (
        <>
          <div>
            { !option_id && chatbot &&
            <>
               <NeuronetCard type={chat_type} name={chatbot.name} about={chatbot.about_text} id={chatbot.id} option_id={0} />
               {chatbot.options &&
                 chatbot.options.map((option: any) => {
                   return <NeuronetCard type={chat_type} id={chatbot.id} name={option.name} about={option.about_text} 
                   option_id={option.id} 
                    />
                 })}
            </>

            }
          </div>
        </>
      )}
    </>
  );
};
