import { Button, Tooltip } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ChatOptionCard } from "./ChatOptionCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import "./ChatCard.scss";

type PropsType = {
  data: any
  parent_id: number|null
};

export const ChatCard: React.FC<PropsType> = ({ data, parent_id }) => {
  const navigate = useNavigate();
  const params = useParams()
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const addChat = (data: any) => () => {
    const {neuronet_id, option_id, type} = data
    navigate(`/admin/neuronet/${type}/${neuronet_id}/${option_id}`)
  };

  return (
    <>
          <div className={"cardsContainer "+currentAlgorithm}>
      {data &&
        data.map((data: any) => {
          return (
            <>
              <div key={parent_id?parent_id+'_'+data.id:data.id} className="chatcard">
                <div className="chatListContainer">
                  <div className="chatListLogo">
                  {data.image && <img src={data.image?.url} style={{width: '100px'}}></img>}
                    </div>
                  <div className="chatListTitle">{data.name}</div>
                  <div className="chatListText">{data.about_text}</div>
                  <div className="chatListAddBtn">
                    <Tooltip title="">
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={addChat({ type: data.output_type, neuronet_id: data.id, option_id: 0 })}
                        icon={<PlusCircleOutlined />} />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <ChatOptionCard data={data.options} parent_id={data.id} />
            </>
            );
          })}
          </div>
    </>
  );
};
