import { Select } from "antd"
import React from "react"
import { UserRoleEnum, UserRoleEnumTitles } from "../../types/UserRole.enum"

type PropsType = {
  value: any,
  onChange: (role: UserRoleEnum)=>void
}

const RoleSelector: React.FC<PropsType> = (props)=>{

  return <>

      <Select defaultValue={UserRoleEnum.Member} value={props.value} onChange={props.onChange}>
        <Select.Option value={UserRoleEnum.SuperAdmin}>{UserRoleEnumTitles[UserRoleEnum.SuperAdmin]}</Select.Option>
        <Select.Option value={UserRoleEnum.Admin}>{UserRoleEnumTitles[UserRoleEnum.Admin]}</Select.Option>
        <Select.Option value={UserRoleEnum.Member}>{UserRoleEnumTitles[UserRoleEnum.Member]}</Select.Option>
      </Select>
</>
}

export default RoleSelector;