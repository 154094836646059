// import { Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import {
  useGetCreateChatsOptionsQuery,
} from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { AdminHistoryTextGenerations } from "./AdminHistoryTextGenerations";
import { AdminHistoryImageGenerations } from "./AdminHistoryImageGenerations";
import { text } from "stream/consumers";

export const AdminHistory: React.FC = () => {
  const { type } = useParams()


  return (
    <>
    {(type==='text' || !type) && <AdminHistoryTextGenerations showFull={type==='text'} /> }
    {(type==='images' || !type) && <AdminHistoryImageGenerations showFull={type==='images'} />}
    </>
  );
};
