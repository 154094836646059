import React from "react";
import { useGetChatMessagesListQuery } from "../../../store/webchatApiSlice";
import { ChatMessageUser } from "../../../Components/Chat/ChatMessageUser";

type PropsType = {
  chat_id: string
}

export const  MessageList: React.FC<PropsType> = ({chat_id})=>{
  
  const { data } = useGetChatMessagesListQuery({ chat_id })

  return <>
    {data && data.map((message: any) => {
      return <ChatMessageUser key={message.id} message={message} />;
    })}
  </>
  
}