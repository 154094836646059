import { Layout } from "antd"
import { Content } from "antd/es/layout/layout"

const OpenLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  return <Layout>
    <Content
                style={{
                    // margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    // background: colorBgContainer,
                }}
            >
              {children}
            </Content>
  </Layout>
}
export default OpenLayout;