import { Button, Col, Flex, Form, Input, Modal, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { useCreateRequestMutation } from "../../../store/supportApiSlice";
import { errorDecode } from "../../../api/errorDecode";
import { showNotification } from "../../../store/notificationSlice";
import { useDispatch, useSelector } from "react-redux";

import "./SupportCreateRequest.scss"
import { IconPlus } from "../../../Components/Icons/IconPlus";
import { FormInstance } from "antd/lib";
import { RootState } from "../../../store/store";
import { PlusOutlined } from "@ant-design/icons";

interface SubmitButtonProps {
  form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch((e) => {
        setSubmittable(false)
      });
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: {span: 24},
    lg: {span: 24},
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
type PropsType = {
  open: boolean,
  close: ()=>void
}
export const SupoortCreateRequest: React.FC<PropsType> = ({open, close})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const dispatch = useDispatch();
  const initialValues = {category:'Общий запрос', priority: 'Низкий', subject: '', text: '' }
  const [formData] = useForm()
  const [saveData, result] = useCreateRequestMutation()
  const save = ()=>{
    const message = formData.getFieldsValue()

    saveData(message) 
    .unwrap()
    .then(() => {
      close()
      return true})
    .catch((error: any) => {
      const description = errorDecode(error)
      dispatch(showNotification({type: 'error',description, message: 'Ошибка сохранения'}))
      return false
    }).finally(()=>{
      formData.resetFields()
    })
  }

  return <Modal 
  getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
  className={'supportCreateRequestModal '+currentAlgorithm} title='Написать заявку' open={open} 
  onOk={save}
  onCancel={close}
  footer={<></>}
  >
    <Form className="supportCreateRequest" form={formData}
    onSubmitCapture={save}
    initialValues={initialValues}
    requiredMark={'optional'}
    {...formItemLayout}
     layout="vertical" variant="filled" size="large">
    <Flex justify="space-between" style={{width: '100%', gap: '24px'}}>

      <Flex style={{width: '50%'}}>
      <Form.Item
      label="Категория проблемы"
      name="category"
      rules={[{ required: true, message: 'Укажите категорию проблемы' }]}
      style={{width: '100%'}}
    >
        <Select>
          <Select.Option key={'Общий запрос'}>Общий запрос</Select.Option>
          <Select.Option key={'Технические нюансы'}>Технические нюансы</Select.Option>
          <Select.Option key={'Предложения для улучшения'}>Предложения для улучшения</Select.Option>
          <Select.Option key={'Обратная связь'}>Обратная связь</Select.Option>
          <Select.Option key={'Другой'}>Другой</Select.Option>          
        </Select>
        </Form.Item>
      </Flex>
      <Flex style={{width: '50%'}}>
      <Form.Item
      label="Приоритет проблемы"
      name="priority"
      rules={[{ required: true, message: 'Укажите приоритет' }]}
      style={{width: '100%'}}
    >
      <Select>
          <Select.Option key={'Низкий'}>Низкий</Select.Option>
          <Select.Option key={'Нормальный'}>Нормальный</Select.Option>
          <Select.Option key={'Высокий'}>Высокий</Select.Option>
          <Select.Option key={'Критический'}>Критический</Select.Option>
        </Select>
        </Form.Item>
      </Flex>
    </Flex>
    <div>
    <Form.Item  label="Тема запроса"
     name="subject" rules={[{ required: true, message: 'Заполните поле тема запроса!' }]}>
      <Input placeholder="Укажите тему запроса"/>
    </Form.Item>
    </div>
    <div>
    <Form.Item
      name="text"
      rules={[{ required: true, message: 'Текст обращения не может быть пустым' }]}
    >
      <Input.TextArea placeholder="Опишите суть проблемы" />
    </Form.Item>
    <Flex justify="flex-end">
      <SubmitButton form={formData}>
      <PlusOutlined />  Отправить заявку
    </SubmitButton>
    </Flex>
    </div>
  </Form>

  </Modal>
}