// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { Card, Flex } from "antd";
import { LkChatCard } from "../../../Components/lk/LkChatCard";

export const LkTextChatsList: React.FC = () => {
  const params = useParams();

  const { data: createChatData } = useGetCreateChatsOptionsQuery({
    output_type: params.type,
  });

  
  const dataText = transformGetCreateChatsOptionsQueryData(createChatData, "text");

  return (
    <>
      <Flex style={{ margin: "12px" }}>
        {dataText && (
          <Card className="lkCardsContainer" title="Работа с текстом">
            <Flex style={{ gap: "24px" }}>
              {dataText.map((item: any) => {
                return (
                  <LkChatCard key={item.key} data={item} parent_id={null} />
                );
              })}
            </Flex>
          </Card>
        )}
      </Flex>
    </>
  );
};
