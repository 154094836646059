import { Avatar, Col, Row, Tooltip } from "antd";
import React from "react";

import "./ChatMessageLoader.scss";

import { ImageViewer } from "../common/ImageViewer";
import { LoadingOutlined } from "@ant-design/icons";
import { MessageType } from "../../types/Message.type";
import { IconEdit } from "../Icons/IconEdit";
import { IconDots } from "../Icons/IconDots";

type PropsType = {
  message: MessageType;
};

export const ChatMessageLoader: React.FC<PropsType> = ({ message }) => {
  const ava_string =
    (message.user?.profile?.first_name
      ? message.user?.profile?.first_name[0]
      : "") +
    (message.user?.profile?.last_name
      ? message.user?.profile?.last_name[0]
      : "");
  const userName =
    (message.user?.profile?.first_name
      ? message.user?.profile?.first_name
      : "") + 
    (message.user?.profile?.last_name
      ? " " + message.user.profile.last_name
      : "");

  const created_at = new Date(message.created_at);
  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes)
    );
  };
  const localDateTime = formatTime(created_at);

  return (
    <div id={`${message.id}`} className="messageLoaderContainer">
<div className="messageLoader">
  <div className="avatarContainer">
    <Avatar
      alt={userName}
      style={{ verticalAlign: "middle" }}
      size="large"
      gap={8}>
      {ava_string}
    </Avatar>
  </div>
  <div className="messasgeBodyContainer">
    <div className="messageBody">
      <div className="dateName">
        <span>{userName}</span>
        <span>{localDateTime}</span>
      </div>
      <div className="messageText">
        <span className="loaderDot"></span>
        <span className="loaderDot"></span>
        <span className="loaderDot"></span>
        <IconEdit />

      </div>
    </div>
  </div>
</div>
</div>);

  // return (
  //   <Row id={`${message.id}`}>
  //     <Col span={24}>
  //       <div className="message">
  //         <div className="avatar">
  //           <Avatar
  //             alt={user}
  //             style={{ verticalAlign: "middle" }}
  //             size="large"
  //             gap={8}>
  //             {ava_string}
  //           </Avatar>
  //         </div>
  //         <div className="messageTextContainer">
  //           <div className="messageText left">
  //             {message.type==='text' && message.text}
  //             {message.type==='loader' && <LoadingOutlined />}
  //             <div className="timeContainer">
  //               <Tooltip title={localDateTime}>
  //                 {created_at.getHours()}:{created_at.getMinutes()}
  //               </Tooltip>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Col>
  //   </Row>
  // );
};
