import { DeleteOutlined, FormOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { useGetNeuronetExamplesQuery, useSaveNeuronetExampleMutation } from "../../../store/neuronetApiSlice";
import { SaveNeuronetExample } from "../../../types/SaveNeuronetExample.type";
import { NeuronetExampleEditor } from "./NeuronetExampleEditor";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";

export const NeuronetExamples: React.FC = () => {
  const dispatch = useAppDispatch()
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)
  const [pagination, setPagination] = useState<any>({ total: 1, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], current: 1 })
  const [openModal, setOpenModal] = useState<boolean>();
  const [editedItem, setEditedItem] = useState<any>()
  const {data, isLoading, refetch } = useGetNeuronetExamplesQuery({ 
    page: pagination.current,
    limit: pagination.pageSize
  })
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Название",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Текст примера",
      dataIndex: "example_text",
      key: "example_text",
    },
    // {
    //   title: "Пользователь",
    //   dataIndex: "user",
    //   key: "user.id",
    //   render: (text: string, record: any) => {
    //     return (
    //       <div>
    //         <Link to={`/admin/user/${record.profile.user_id}`}>
    //           {record.profile?.first_name}&nbsp;
    //           {record.profile?.last_name}
    //         </Link>
    //       </div>
    //     )
    //   }

    // },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span>
              <Tooltip title='Редактировать'>
                <FormOutlined onClick={handleOpenEditor(record)} />
              </Tooltip>
            </span>
            <span>
              <Popconfirm title="Вы уверены?" onConfirm={() => sureDelete(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />} >
                <Tooltip title='Удалить'>
                  <DeleteOutlined color="#990000" />
                </Tooltip>
              </Popconfirm>
            </span>
            {/* <span>
              <InfoCircleOutlined onClick={() => navigate(`/admin/user/${record.id}`)} />
            </span> */}

          </div>
        );
      }
    },
  ];
  const sureDelete = (record: any)=>{
    refetch()
  }
  const handleOpenEditor = (option: SaveNeuronetExample)=>()=>{
    setEditedItem(option)
    setOpenModal(true)

  }
  const changedTable = (e: any) => {
    setPagination(e)
  };
  const closeEditor = () => {
    setOpenModal(false)
    refetch()
  }
  const [saveData, result] = useSaveNeuronetExampleMutation()
  const save = async (data: SaveNeuronetExample)=>{
    const {id, type, example_text}: SaveNeuronetExample = data
    return saveData({id, type, example_text})
    .unwrap()
    .then(() => { return true})
    .catch((error: any) => {
      const description = errorDecode(error)
      dispatch(showNotification({type: 'error',description, message: 'Ошибка сохранения'}))
      return false
    })

  }
  if(isLoading){
    return <>Loading...</>
  }

  return <>
    {/* <Button onClick={()=>{handleOpenEditor(null)}}>add</Button> */}
    
    <div className="searchBar">
        <div>
          <span>
          </span>
          <span>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenEditor({
                type: "chatgpt", example_text: '', 
                id: undefined
              })}>
              Добавить пример 
            </Button>
          </span>
        </div>
      </div>
    <Table
        bordered
        columns={columns}
        dataSource={data[0]||[]}
        pagination={pagination}
        loading={isLoading}
        onChange={changedTable}
        className={currentAlgorithm === 'dark' ? 'table_dark' : ""}
        rowKey={record => record.id}></Table>
    {openModal && <NeuronetExampleEditor editedItem={editedItem} save={save} closeModal={closeEditor} modalVisible={openModal} />}
  </>
}