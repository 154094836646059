import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const defaultAlgorithm = localStorage.getItem('defaultThemeAlgorithm') || 'light'

const designModeSlice = createSlice({
    name: 'designMode',
    initialState: {
        currentAlgorithm: defaultAlgorithm,
    },
    reducers:{
        toggleAlgorithm: (state, action)=>{
            state.currentAlgorithm = state.currentAlgorithm==='dark'?'light':'dark'
            localStorage.setItem('defaultThemeAlgorithm', state.currentAlgorithm) 
        },
        setAlgorithm: (state, action)=>{
            state.currentAlgorithm = action.payload.algorithm
            localStorage.setItem('defaultThemeAlgorithm', state.currentAlgorithm) 
        }
    }
})

export const { toggleAlgorithm } = designModeSlice.actions

export default designModeSlice.reducer

export const getCurrentAlgorithm = (state: RootState) => state.designMode.currentAlgorithm
