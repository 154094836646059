import {
  Button,
  Flex,
  GetProp,
  Modal,
  Pagination,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useGetImagesQuery } from "../../../store/imgaesApiSlice";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { UploadProps } from "antd/lib";
import { useAppSelector } from "../../../store/store";
import { ImageType } from "../../../types/Image.type";

import "./ImageSelector.scss"
import { ImageInfo } from "./ImageInfo";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

type PropsType = {
  onSelect: (image: ImageType) => void;
  folder: string;
};

export const ImageSelector: React.FC<React.PropsWithChildren<PropsType>> = ({
  onSelect,
  folder,
  children,
}) => {
  const [selectedImage, setImage] = useState<ImageType | null>(null);
  const [selectedChildImage, setChildImage] = useState<ImageType | null>(null);
  const [loading, setLoading] = useState(false);
  const token = useAppSelector((state) => state.auth.token);

  const [open, setOpen] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });
  const {
    data: imagesList,
    isLoading,
    refetch,
  } = useGetImagesQuery({
    page: pagination.page,
    limit: pagination.limit,
  });
  useEffect(() => {
    if (imagesList) {
      setPagination({ ...pagination, total: imagesList[1] });
    }
  }, [imagesList]);
  const cancel = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  const changePage = (page: number) => {
    setPagination({ ...pagination, page: page });
  };

  const selectImage = (image: any) => () => {
    setImage(image);
  };
  const selectChildImage = (image: any) => () => {
    setChildImage(image);
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const img = info.file.response.find((i: any) => true);
      setLoading(false);
      refetch();
      // setImageUrl(img.url);
      // onUploadComplete(img)
    }
  };
  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = <Button type="primary" icon={<UploadOutlined />}>Загрузить</Button>;

  const modalOk = () => {
    if (selectedChildImage) {
      onSelect(selectedChildImage);
      setOpen(false);
    }
    else if (selectedImage) {
      onSelect(selectedImage);
      setOpen(false);
    }

  }

  return (
    <div className="imageSelector">
      <div onClick={openModal}>{children}</div>
      <Modal
        getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
        open={open}
        loading={isLoading}
        onCancel={cancel}
        onOk={modalOk}
        className="imageSelectorModal"
        width={"90vw"}
        height={"70vh"}>
        <Flex className='imageSelectorContainer' style={{ gap: "24px", flexDirection: "column", height: '100%' }}>
          <Flex className="imageSelectorBodyContainer">
            <Flex className='imageSelectorBodyImages'>
              <Flex className='imageSelectorHeader' >
                <Flex className='imageSelectorHeaderLabel'>Картинки пользователя</Flex>
                <Flex className='imageSelectorHeaderBtn'>
                  <Upload
                    name="file"
                    action={`/api/image/upload/${folder}`}
                    headers={{ authorization: "bearer " + token }}
                    // listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}>
                    {uploadButton}
                  </Upload>
                </Flex>
              </Flex>
              <Flex className="imageSelectorBodyImagesList">
                {!isLoading &&
                  imagesList &&
                  imagesList[0].map((image: ImageType) => (
                    <div
                      key={image.id}
                      onClick={selectImage(image)}
                      style={{
                        width: "100px",
                        height: "100px",
                        background: `url(${image.url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                      }}>
                      {/* <img src={image.url}  width={'100px'}/> */}
                    </div>
                  ))}
              </Flex>
              <Flex className="imageSelectorBodyImagesPagination">
                <Pagination
                  pageSize={pagination.limit}
                  total={pagination.total}
                  onChange={changePage}
                />
              </Flex>
            </Flex>
            <Flex className="imageSelectorSelectedImage">
              {selectedImage && <ImageInfo image={selectedImage} />}

              {selectedImage &&
                selectedImage.childs?.map((img: any) => {
                  return <ImageInfo image={img} />
                }
                )}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
};
