import { AnyAction, Store, ThunkDispatch, configureStore } from '@reduxjs/toolkit'
import auth from './authSlice'
import user from './userSlice'
import designMode from './designModeSlice'
import layoutSlice from './layoutSlice'

//import users from './usersSlice'
import notifications from './notificationSlice'
import { createLogger } from 'redux-logger'
import { logsApi } from './logsSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { apiSlice } from '../api/apiSlice'

const logger = createLogger({})
export const store = configureStore({
  reducer: {
    auth,
    user,
    designMode,
    notifications,
    layoutSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(logsApi.middleware)
    .concat(logger)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};
// you can also create some redux hooks using the above explicit types

export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch