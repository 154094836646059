import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Icon from "@ant-design/icons/lib/components/Icon";

export const IconStarSelected: React.FC = ()=>{


  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);

const svgIcon = ()=> <svg className={"customIcon selected "+currentAlgorithm} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M5.12508 0.690768C5.51557 -0.00792538 6.48411 -0.00792511 6.8746 0.690768L8.28855 3.22072L11.0343 3.84037C11.7926 4.01149 12.0919 4.97147 11.5749 5.57441L9.70305 7.75765L9.98605 10.6706C10.0642 11.475 9.28064 12.0683 8.57066 11.7423L5.99984 10.5616L3.42902 11.7423C2.71904 12.0683 1.93547 11.475 2.01362 10.6706L2.29662 7.75765L0.424769 5.57441C-0.0921774 4.97147 0.207119 4.01149 0.9654 3.84037L3.71113 3.22072L5.12508 0.690768Z" fill="#7479F6"/>
</svg>
  return <Icon component={svgIcon} />
}