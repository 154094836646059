import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string
}

export const IconImgGenerate: React.FC<PropsType> = ({className}  )=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
);
  
let classNames: Array<string> = ['customIcon', currentAlgorithm]
if(className) classNames.push(className)

  return <svg className={classNames.join(' ')} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.166667 2.99996C0.166667 1.71129 1.21134 0.666626 2.5 0.666626H9.5C10.7887 0.666626 11.8333 1.71129 11.8333 2.99996V6.76189L10.5228 5.90412C9.51107 5.24187 8.18668 5.31224 7.25078 6.07798L4.19516 8.57803C3.55481 9.10195 2.64865 9.1501 1.95638 8.69698L0.166667 7.52553V2.99996ZM10.0436 6.63624L11.8333 7.80767V9.99996C11.8333 11.2886 10.7887 12.3333 9.5 12.3333H2.5C1.21134 12.3333 0.166667 11.2886 0.166667 9.99996V8.5713L1.47718 9.42909C2.48895 10.0913 3.81334 10.021 4.74925 9.25524L7.80486 6.75519C8.44522 6.23126 9.35138 6.18312 10.0436 6.63624ZM3.95833 5.91663C4.76375 5.91663 5.41667 5.26371 5.41667 4.45829C5.41667 3.65288 4.76375 2.99996 3.95833 2.99996C3.15292 2.99996 2.5 3.65288 2.5 4.45829C2.5 5.26371 3.15292 5.91663 3.95833 5.91663Z" fill="#2B3858"/>
</svg>

}