import { Modal, Tag } from "antd";
import React from "react";
import { SupportRequestItem } from "../../../types/SupportRequestItem.type";
import { SupportStatusEnum } from "../../../types/SupportStatus.enum";

import './SupportRequestDetails.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

type PropsType = {
  open: boolean,
  item: SupportRequestItem
  close: ()=>void
}

export const SupportRequestDetails: React.FC<PropsType> = ({ open, item, close })=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  return <Modal   getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
  open={open} 
  width={'448px'} 
  height={'213px'} 
  onCancel={close}
  className={"supportRequestDetails "+ currentAlgorithm}
  title={<div className="supportRequestDetailsHeaders">
    <span>#{item.id} </span>
  <span>
    {item.status===SupportStatusEnum.OPEN && <Tag className="statusOpenTag">Открыта</Tag>}
    {item.status===SupportStatusEnum.CLOSED && <Tag className="statusClosedTag">Закрыта</Tag>}
  </span>
  </div>}
  footer={null}
  >
    <div className="supportRequestDetailsBody">
      <div className="supportRequestDetailsBodySubject">{item.subject}</div>
      <div className="supportRequestDetailsBodyText">{item.messages[0].text}</div>
    </div>
    

  </Modal>
}