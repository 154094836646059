import { Tabs } from "antd";
import React from "react";
import { NeuronetsList } from "./NeuronetsList";
import { NeuronetOptions } from "./NeuronetOptions";
import { NeuronetExamples } from "./NeuronetExamples";
import { useLocation, useNavigate } from "react-router-dom";

export const NeuronetsMain: React.FC = ()=>{
  
  const navigate = useNavigate()
  const location = useLocation();
  //const [path, setPath] = useState<any>(location.pathname)

  const onTabChange = (path: any) => {
    
    navigate(path);
    
  }

  return <>
    <Tabs 
    defaultValue={location.pathname}
    activeKey={location.pathname}
    onChange={onTabChange}
    defaultActiveKey="1" items={[
      {
        key: '/admin/neuronets/accounts',
        label: 'Аккаунты нейросетей',
        children: <NeuronetsList /> ,
      },
      {
        key: '/admin/neuronets/options',
        label: 'Опции нейросетей',
        children: <NeuronetOptions />,
      },
      {
        key: '/admin/neuronets/examples',
        label: 'Примеры ',
        children: <NeuronetExamples />,
      },
    ]}  />

  </>
}