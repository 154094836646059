import { Avatar, Col, Row, Tooltip } from "antd";
import React from "react";

import "./ChatMessageReply.scss";
import { ImageViewer } from "../common/ImageViewer";
import { LoadingOutlined } from "@ant-design/icons";
import { MessageType } from "../../types/Message.type";

type PropsType = {
  message: MessageType;
};

export const ChatMessageReply: React.FC<PropsType> = ({ message }) => {
  const ava_string =
    (message.user?.profile?.first_name
      ? message.user?.profile?.first_name[0]
      : "") +
    (message.user?.profile?.last_name
      ? message.user?.profile?.last_name[0]
      : "");
  const userName =
    (message.user?.profile?.first_name
      ? message.user?.profile?.first_name
      : "") +
    (message.user?.profile?.last_name
      ? " " + message.user.profile.last_name
      : "");

  const created_at = new Date(message.created_at);
  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes)
    );
  };

  const localDateTime = formatTime(created_at);

  return (
    <div id={`${message.id}`} className="messageReplyContainer">
      <div className="messageReply">
        <div className="avatarContainer">
          <Avatar
            alt={userName}
            style={{ verticalAlign: "middle" }}
            size="large"
            gap={8}>
            {ava_string}
          </Avatar>
        </div>
        <div className="messasgeBodyContainer">
          <div className="messageBody">
            <div className="dateName">
              <span>{userName}</span>
              <span>{localDateTime}</span>
            </div>
            <div className="messageText">{message.text}</div>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <Row id={`${message.id}`}>
  //     <Col span={24}>
  //       <div className="message">
  //         <div className="avatar">
  //           <Avatar
  //             alt={user}
  //             style={{ verticalAlign: "middle" }}
  //             size="large"
  //             gap={8}>
  //             {ava_string}
  //           </Avatar>
  //         </div>
  //         <div className="messageTextContainer">
  //           <div className="messageText left">
  //             {message.type==='text' && message.text}
  //             {message.type==='loader' && <LoadingOutlined />}
  //             <div className="timeContainer">
  //               <Tooltip title={localDateTime}>
  //                 {created_at.getHours()}:{created_at.getMinutes()}
  //               </Tooltip>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Col>
  //   </Row>
  // );
};
