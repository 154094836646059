import { apiSlice } from "../api/apiSlice";
import { CreateUserType, UpdateUsersStatusType, UpdateUserType, User } from "../types/User.type";

export const userApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['User', 'CurrentUser', 'UserInfo']})
.injectEndpoints({
    endpoints: builder => ({

        getUser: builder.query({
            query: (params) => `/api/user/byid/${params.user_id}`,
            providesTags: ['UserInfo']
        }),
        getCurrentUser: builder.query({
            query: () => '/api/user/current',
            providesTags: ['CurrentUser']
        }),
        getUsers: builder.query({
            query: params => ({
              url: '/api/user',
              method: 'GET',
              params
            }),
            providesTags: ['User']
        }),
        createUser: builder.mutation<CreateUserType, Partial<User>>({
            query: user => ({
                url: `/api/user/create`,
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['User']

        }),
        updateUser: builder.mutation<UpdateUserType, Partial<User>>({
            query: user => ({
                url: `/api/user/update`,
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation<UpdateUserType, Partial<User>>({
            query: user => ({
                url: `/api/user`,
                method: 'DELETE',
                body: user
            }),
            invalidatesTags: ['User']
        }),
        updateUserStatus: builder.mutation<UpdateUsersStatusType, any >({
            query: payload => ({
                url: '/api/user/status/update',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['User']
        }),
        getMessagesList: builder.query({
            query: params => ({
                url: '/api/chat/list',
                method: 'GET',
                params
            })
        })
    })
})

export const {
    useGetUserQuery,
    useGetCurrentUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetMessagesListQuery,
    useUpdateUserStatusMutation
} = userApiSlice